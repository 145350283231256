import {
  format,
  getTime,
  formatDistanceToNow,
  differenceInDays,
  differenceInHours,
} from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date, hideYear) {
  return format(
    new Date(date.replace(/-/g, "/")),
    hideYear ? "MMM do" : "dd MMM yyyy"
  );
}

export function formatDate(date) {
  return format(date, "yyyy-MM-dd");
}

export function fDateTime(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd MMM yyyy hh:mm aa");
}
export function fTime(date) {
  return format(new Date(date.replace(/-/g, "/")), "hh:mm aa");
}

export function fTimestamp(date) {
  return getTime(new Date(date.replace(/-/g, "/")));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date.replace(/-/g, "/")), {
    addSuffix: true,
    includeSeconds: true,
  });
}

export function fInterval(startDate, endDate) {
  return differenceInDays(
    new Date(endDate.replace(/-/g, "/")),
    new Date(startDate.replace(/-/g, "/"))
  );
}
export function fTimeInterval(startDate, endDate) {
  return differenceInHours(
    new Date(endDate.replace(/-/g, "/")),
    new Date(startDate.replace(/-/g, "/"))
  );
}
