import { useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Button,
  Typography,
  Box,
  Stack,
  Grid,
  ButtonGroup,
  Link,
  Divider,
  Alert,
} from "@mui/material";
// routes
import { fCurrency } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";
import { MasterCardIcon, VisaIcon, VerveIcon } from "../../../assets";

// ----------------------------------------------------------------------
const FEATURES = [
  "Self-manage booking",

  "Comprehensive dashboard",

  "Customer support",

  "No hidden fee",

  "No commission",

  "Export booking",
  "Add booking",

  "Flexible pricing",

  "Customer support",

  "Unlimited Listing",

  "Analytics and reports",

  "Message & email support etc",
];

// ----------------------------------------------------------------------

Subscriptions.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
  currentPlan: PropTypes.string,
  currentDuration: PropTypes.string,
  duration: PropTypes.string,
  handleSelected: PropTypes.func,
};
const cardMatch = {
  mastercard: <MasterCardIcon />,
  visa: <VisaIcon />,
  verve: <VerveIcon />,
  "sandbox card scheme": <VisaIcon />,
};
export default function Subscriptions() {
  const navigate = useNavigate();
  const { user, getPlans } = useAuth();
  const [plans, setPlans] = useState([]);
  const [duration, setDuration] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState({});
  let paymentMethod = (user && user.paymentMethods) || [];
  if (paymentMethod.length > 0) {
    paymentMethod = paymentMethod[0];
  } else {
    paymentMethod = {};
  }

  useEffect(() => {
    const get = async () => {
      try {
        setPlans(await getPlans());
      } catch (err) {
        console.log(err);
      }
    };
    get();
  }, [getPlans]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: 1 }}
          >
            <Stack
              alignItems={selectedPlan.name ? "start" : "center"}
              alignSelf={selectedPlan.name ? "start" : "center"}
              justifyContent="center"
              sx={{
                alignSelf: selectedPlan.name ? "start" : "center",
                flexGrow: 1,
              }}
              spacing={selectedPlan.name ? 3 : 0}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontSize: 24, fontWeight: 500 }}
              >
                {(selectedPlan.name && "Plan details") || "Choose your plan"}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: 14,
                  fontWeight: 300,
                  color: "rgba(98, 98, 98, 0.7)",
                }}
              >
                {(selectedPlan.name && (
                  <Box>
                    View the{" "}
                    <Link sx={{ fontSize: 14, fontWeight: 400 }}>
                      Terms of Service
                    </Link>
                    &nbsp; and&nbsp;
                    <Link sx={{ fontSize: 14, fontWeight: 400 }}>
                      privacy policy
                    </Link>
                  </Box>
                )) ||
                  "Get the right plan for your business, Plans can be upgraded in the future"}
              </Typography>
            </Stack>
            {(selectedPlan.name && (
              <Button
                sx={{
                  boxShadow: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  height: 44,
                  px: 2,
                  fontSize: 14,
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Choose Plan
              </Button>
            )) || (
              <ButtonGroup
                sx={{
                  boxShadow: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  border: "none",
                }}
              >
                <Button
                  sx={{
                    height: 44,
                    border: "none",
                    bgcolor:
                      duration === "monthly" ? "primary.main" : "common.white",
                    color:
                      duration === "monthly"
                        ? "common.white"
                        : "rgba(98, 98, 98, 1)",
                  }}
                  onClick={() => setDuration("monthly")}
                >
                  Monthly
                </Button>
                <Button
                  sx={{
                    height: 44,
                    border: "none",
                    bgcolor:
                      duration === "yearly" ? "primary.main" : "common.white",
                    color:
                      duration === "yearly"
                        ? "common.white"
                        : "rgba(98, 98, 98, 1)",
                  }}
                  onClick={() => setDuration("yearly")}
                >
                  Yearly
                </Button>
              </ButtonGroup>
            )}
          </Stack>
          {(selectedPlan.name && (
            <Stack
              sx={{ width: 1 }}
              alignItems="start"
              justifyContent="start"
              spacing={4}
            >
              <Stack
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  borderRadius: 2,
                  maxWidth: 600,
                  p: 3,
                  py: 2,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: 20, fontWeight: 500 }}
                >
                  {selectedPlan.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: 20, fontWeight: 700 }}
                >
                  {fCurrency(
                    duration === "monthly"
                      ? selectedPlan.amountMonthly
                      : selectedPlan.amountYearly
                  )}
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "rgba(98, 98, 98, 0.7)",
                    }}
                  >
                    &nbsp; /{duration}
                  </Typography>
                </Typography>
                <Grid container sx={{ pt: 5, pb: 4 }} spacing={2}>
                  {FEATURES.map((feature, index) => (
                    <Grid item md={6} xs={12} key={index}>
                      <Stack direction="row" spacing={1}>
                        <Icon
                          icon="icon-park-outline:done-all"
                          style={{ color: "rgba(65, 177, 26, 1)" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "rgba(98, 98, 98, 1)",
                          }}
                        >
                          {feature}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
                <Divider flexItem sx={{ borderColor: "rgba(0, 0, 0, 0.25)" }} />
                <Button
                  sx={{
                    color: "rgba(98, 98, 98, 1)",
                    fontSize: 14,
                    fontWeight: 400,
                    textDecoration: "underline",
                  }}
                >
                  View all Features
                </Button>
              </Stack>
              <Stack
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  borderRadius: 2,
                  maxWidth: 600,
                  width: 1,
                  p: 3,
                  py: 2,
                }}
                spacing={2}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: 20, fontWeight: 500 }}
                >
                  Billing
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon
                    icon="material-symbols:payments"
                    style={{ color: "rgba(64, 60, 60, 0.7)" }}
                  />
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      Billing Circle
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      {sentenceCase(duration)} for{" "}
                      {fCurrency(
                        duration === "monthly"
                          ? selectedPlan.amountMonthly
                          : selectedPlan.amountYearly,
                        false,
                        false
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                {selectedPlan.amountMonthly > 0 && (
                  <Alert
                    severity="success"
                    icon={<Icon icon="ep:warning" />}
                    sx={{
                      py: 0,
                      px: 1,
                      fontSize: 13,
                      fontWeight: 400,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Pay yearly and save ₦39,520
                  </Alert>
                )}

                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{ borderColor: "rgba(0, 0, 0, 0.25)" }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {paymentMethod.type ? (
                      cardMatch[paymentMethod.type.toLowerCase()]
                    ) : (
                      <Icon icon="ph:empty" />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(64, 60, 60, 1)",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {paymentMethod.type
                        ? `Payment method:\n${paymentMethod.type} ending with ${paymentMethod.last4}`
                        : "No Payment Method"}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{
                      color: "rgba(65, 177, 26, 1)",
                      textDecoration: "underline",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                    onClick={() => navigate("/settings/payment")}
                  >
                    Update
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )) || (
            <Stack direction="row" spacing={5}>
              {plans.map((plan, index) => (
                <Card
                  key={index}
                  sx={{
                    py: 2,
                    px: 4,
                    boxShadow: "0px 1px 33.4px -10px rgba(0, 0, 0, 0.14)",
                    borderRadius: 3,
                    position: "relative",
                    pt: 4,
                  }}
                >
                  {index === 1 && (
                    <Box
                      sx={{
                        top: 30,
                        right: "-30px",
                        position: "absolute",
                        bgcolor: "rgba(151, 71, 255, 1)",
                        fontSize: 13,
                        fontWeight: 500,
                        color: "common.white",
                        px: 4,
                        py: 0.5,
                        transform: "rotate(40deg)",
                      }}
                    >
                      Best Choice
                    </Box>
                  )}
                  <Stack sx={{ width: 1, height: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        "&:before": {
                          width: 10,
                          height: 10,
                          bgcolor:
                            index === 0
                              ? "rgba(65, 177, 26, 1)"
                              : "rgba(111, 46, 197, 1)",
                          borderRadius: "50%",
                          content: '""',
                          display: "inline-block",
                          filter: "blur(2px)",
                          mr: "10px",
                        },
                        color:
                          index === 0
                            ? "rgba(65, 177, 26, 1)"
                            : "rgba(111, 46, 197, 1)",
                        fontSize: 16,
                        fontWeight: 400,
                        pr: 10,
                      }}
                    >
                      {plan.name}
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{
                        color: "primary.main",
                        textTransform: "capitalize",
                      }}
                    >
                      {plan.description}
                    </Typography>
                    <Box sx={{ height: 20, mt: 3 }} />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        my: 2,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "rgba(64, 60, 60, 1)",
                          fontSize: 32,
                          fontWeight: 600,
                        }}
                      >
                        {fCurrency(
                          duration === "monthly"
                            ? plan.amountMonthly
                            : plan.amountYearly,
                          false,
                          false
                        )}
                      </Typography>
                      {index === 1 && (
                        <Typography
                          gutterBottom
                          component="span"
                          variant="body2"
                          sx={{
                            alignSelf: "flex-end",
                            color: "rgba(98, 98, 98, 0.7)",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          / {duration}
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ height: 20, mt: 1 }} />
                    <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
                      {JSON.parse(plan.features).map((item, i) => (
                        <Stack
                          key={i}
                          component="li"
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Icon
                            icon="ph:seal-check-bold"
                            sx={{ width: 20, height: 20 }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: "rgba(64, 60, 60, 1)",
                            }}
                          >
                            {item}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                    <Box
                      sx={{
                        flexGrow: 1,
                        flexShrink: 0,
                        mb: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        alignSelf: "stretch",
                      }}
                    >
                      <Button
                        onClick={() => setSelectedPlan(plan)}
                        fullWidth
                        size="large"
                        variant={index === 0 ? "contained" : "outlined"}
                        sx={{
                          bgcolor:
                            index === 0
                              ? "rgba(65, 177, 26, 1)"
                              : "common.white",
                          borderColor:
                            index === 0 ? "none" : "rgba(151, 71, 255, 1)",
                          color:
                            index === 0
                              ? "common.white"
                              : "rgba(151, 71, 255, 1)",
                          boxShadow: "none",
                          fontSize: 16,
                          fontWeight: 500,
                          "&:hover": {
                            bgcolor:
                              index === 0
                                ? "rgba(65, 177, 26, 1)"
                                : "common.white",
                            borderColor:
                              index === 0 ? "none" : "rgba(151, 71, 255, 1)",
                            color:
                              index === 0
                                ? "common.white"
                                : "rgba(151, 71, 255, 1)",
                          },
                        }}
                      >
                        Choose
                      </Button>
                    </Box>
                  </Stack>
                </Card>
              ))}
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
