import { last } from "lodash";
import PropTypes from "prop-types";
// material
import { Link as RouterLink } from "react-router-dom";
import { Typography, Box, Link, Breadcrumbs } from "@mui/material";

// ----------------------------------------------------------------------

const Separator = <span style={{ color: "rgba(64, 60, 60, 0.7)" }}>&gt;</span>;

LinkItem.propTypes = {
  link: PropTypes.object,
};

function LinkItem({ link }) {
  const { href, name, icon } = link;
  return (
    <Link
      to={href}
      key={name}
      variant="body2"
      component={RouterLink}
      sx={{
        lineHeight: 2,
        display: "flex",
        alignItems: "center",
        "& > div": { display: "inherit" },
        color: "rgba(64, 60, 60, 0.7)",
        fontSize: 15,
        fontWeight: 400,
      }}
    >
      {icon && (
        <Box
          sx={{
            mr: 1,
            "& svg": { width: 20, height: 20 },
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
}

MBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool,
};

export default function MBreadcrumbs({ links, activeLast = false, ...other }) {
  const currentLink = last(links).name;

  const listDefault = links.map((link) => (
    <LinkItem key={link.name} link={link} />
  ));
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: "rgba(64, 60, 60, 0.7)",
            fontSize: 15,
            fontWeight: 400,
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={Separator} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
}
