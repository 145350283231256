import { Icon } from "@iconify/react";
import { capitalCase } from "change-case";
import PropTypes from "prop-types";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Button,
  IconButton,
  FormControlLabel,
  Divider,
  Switch,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
// hooks
import useAuth from "../../../hooks/useAuth";
import { instanceId } from "../../../utils/apis";
// utils

const DETAILS = [
  {
    title: "Account activity and policies",
    subtitle:
      "Confirm your booking and account activity, and learn about important Afritas policies.",
    children: [
      {
        title: "Account activity",
        subtitle: "Never miss important messages from your Hosts or guests.",
        key: "activity",
      },
      {
        title: "Guest policies",
        subtitle: "Never miss important messages from your Hosts or guests.",
        key: "policies",
      },
      {
        title: "Listing activity",
        subtitle: "Never miss important messages from your Hosts or guests.",
        key: "listing",
      },
    ],
  },
  {
    title: "Reminders",
    subtitle:
      "Get important reminders about your reservations, listings, and account activity.",
    children: [
      {
        title: "Reminders",
        subtitle: "Never miss important messages from your Hosts or guests.",
        key: "reminders",
      },
    ],
  },
  {
    title: "Guest and Host messages",
    subtitle:
      "Keep in touch with your Host or guests before and during your trip.",
    children: [
      {
        title: "Message",
        subtitle: "Never miss important messages from your Hosts or guests.",
        key: "message",
      },
    ],
  },
];

Edit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setValue: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
};
function Edit({
  open,
  title,
  subtitle,
  type,
  values,
  setValue,
  isLoading,
  handleSave,
  handleClose,
}) {
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ position: "relative" }}>
        <DialogTitle sx={{ fontSize: { md: 20, xs: 16 }, fontWeight: 500 }}>
          {title}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 1 }}>
        <DialogContentText
          sx={{ mb: 5, fontSize: { md: 14, xs: 12 }, fontWeight: 400 }}
        >
          {subtitle}
        </DialogContentText>
        <Stack
          direction="column"
          spacing={2}
          divider={
            <Divider
              flexItem
              orientation="horizontal"
              sx={{ borderColor: "rgba(98, 98, 98, 0.1)" }}
            />
          }
        >
          <FormControlLabel
            label="Message"
            labelPlacement="start"
            sx={{
              "& span": {
                fontSize: { md: 16, xs: 15 },
                fontWeight: 400,
                color: "rgba(64, 60, 60, 1)",
              },
              justifyContent: "space-between",
              mx: 0,
            }}
            control={
              <Switch
                sx={{
                  "& .MuiSwitch-thumb": {
                    mt: "6px",
                    ml: "5px",
                    width: 10,
                    height: 10,
                    color: "rgba(98, 98, 98, 1)",
                  },
                  "& .MuiSwitch-track": {
                    color: "common.white",
                    border: "2px solid",
                    borderColor: "rgba(98, 98, 98, 1)",
                    bgcolor: "transparent",
                    height: "1rem",
                  },
                  "& .Mui-checked .MuiSwitch-thumb": {
                    color: "rgba(65, 177, 26, 1) !important",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    color: "rgba(65, 177, 26, 1) !important",
                    borderColor: "rgba(65, 177, 26, 1) !important",
                  },
                }}
                checked={values.email === 1}
                onChange={() =>
                  setValue((prev) => ({
                    ...prev,
                    email: prev.email === 1 ? 0 : 1,
                  }))
                }
              />
            }
          />
          <FormControlLabel
            label="SMS"
            labelPlacement="start"
            sx={{
              "& span": {
                fontSize: 16,
                fontWeight: 400,
                color: "rgba(64, 60, 60, 1)",
              },
              justifyContent: "space-between",
              mx: 0,
            }}
            control={
              <Switch
                sx={{
                  "& .MuiSwitch-thumb": {
                    mt: "6px",
                    ml: "5px",
                    width: 10,
                    height: 10,
                    color: "rgba(98, 98, 98, 1)",
                  },
                  "& .MuiSwitch-track": {
                    color: "common.white",
                    border: "2px solid",
                    borderColor: "rgba(98, 98, 98, 1)",
                    bgcolor: "transparent",
                    height: "1rem",
                  },
                  "& .Mui-checked .MuiSwitch-thumb": {
                    color: "rgba(65, 177, 26, 1) !important",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    color: "rgba(65, 177, 26, 1) !important",
                    borderColor: "rgba(65, 177, 26, 1) !important",
                  },
                }}
                checked={values.sms === 1}
                onChange={() =>
                  setValue((prev) => ({
                    ...prev,
                    sms: prev.sms === 1 ? 0 : 1,
                  }))
                }
              />
            }
          />
          <FormControlLabel
            label="Browser notification"
            labelPlacement="start"
            sx={{
              "& span": {
                fontSize: 16,
                fontWeight: 400,
                color: "rgba(64, 60, 60, 1)",
              },
              justifyContent: "space-between",
              mx: 0,
            }}
            control={
              <Switch
                sx={{
                  "& .MuiSwitch-thumb": {
                    mt: "6px",
                    ml: "5px",
                    width: 10,
                    height: 10,
                    color: "rgba(98, 98, 98, 1)",
                  },
                  "& .MuiSwitch-track": {
                    color: "common.white",
                    border: "2px solid",
                    borderColor: "rgba(98, 98, 98, 1)",
                    bgcolor: "transparent",
                    height: "1rem",
                  },
                  "& .Mui-checked .MuiSwitch-thumb": {
                    color: "rgba(65, 177, 26, 1) !important",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    color: "rgba(65, 177, 26, 1) !important",
                    borderColor: "rgba(65, 177, 26, 1) !important",
                  },
                }}
                checked={values.notification === 1}
                onChange={() =>
                  setValue((prev) => ({
                    ...prev,
                    notification: prev.notification === 1 ? 0 : 1,
                  }))
                }
              />
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start" }}>
        <LoadingButton
          onClick={handleSave}
          loading={isLoading}
          sx={{
            px: 4,
            py: 1.2,
            boxShadow: "none",
            fontSize: 16,
            fontWeight: 500,
          }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default function Notifications() {
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateSettings } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [isNotification, setNotification] = useState(true);
  const [values, setValues] = useState({});

  const handleClose = () => {
    setIsEdit(false);
    setSelected({});
    setValues({});
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      await updateSettings(
        { key: selected.value, value: values },
        "notification"
      );
      enqueueSnackbar("Settings updated successfully!", {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      enqueueSnackbar(err.message || err, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    setNotification(
      "Notification" in window && window.Notification.permission === "granted"
    );
    return () => {
      setNotification(true);
    };
  }, []);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stack spacing={4}>
            {!isNotification && (
              <Card
                sx={{
                  py: 1,
                  px: 3,
                  boxShadow: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  borderRadius: 1,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Icon
                      icon="fluent-emoji:warning"
                      style={{ width: 35, height: 35 }}
                    />

                    <Stack alignItems="start" spacing={0.5}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Web push notifications are off.
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: 14,
                          fontWeight: 300,
                          color: "rgba(98, 98, 98, 1)",
                        }}
                      >
                        Turn on notifications to get notified of new responses
                        on your device.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      if ("Notification" in window) {
                        const beamsClient = new PusherPushNotifications.Client({
                          instanceId,
                        });
                        Notification.requestPermission().then((permission) => {
                          if (permission === "granted") {
                            setNotification(true);
                            beamsClient
                              .start()
                              .then(() => {
                                const userId =
                                  (user && user.user && user.user.uid) || "";
                                beamsClient.addDeviceInterest("general");
                                if (userId) {
                                  beamsClient.addDeviceInterest(userId);
                                }
                              })
                              .then(() =>
                                console.log(
                                  "Successfully registered and subscribed!"
                                )
                              )
                              .catch(console.error);
                          }
                        });
                      } else {
                        enqueueSnackbar(
                          "Your device cannot receive web notification at this time!",
                          {
                            variant: "error",
                          }
                        );
                      }
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    Turn On
                  </Button>
                </Stack>
              </Card>
            )}
            <Stack spacing={{ md: 5, xs: 2 }}>
              {DETAILS.map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    py: 1,
                    px: 3,
                    boxShadow: {
                      md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                      xs: "none",
                    },
                    borderRadius: 1,
                  }}
                >
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: { md: "#000", xs: "rgba(64, 60, 60, 1)" },
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { md: 14, xs: 12 },
                        fontWeight: 300,
                        color: {
                          md: "rgba(98, 98, 98, 1)",
                          xs: "rgba(95, 95, 95, 1)",
                        },
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                    <Stack
                      spacing={2}
                      sx={{ pt: 4, pb: 2 }}
                      divider={
                        <Divider
                          flexItem
                          orientation="horizontal"
                          sx={{ color: "rgba(0, 0, 0, 0.1)" }}
                        />
                      }
                    >
                      {item.children.map((child, i) => (
                        <Stack
                          key={i}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Stack alignItems="start" spacing={0.5}>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: { md: 400, xs: 500 },
                                color: {
                                  md: "rgba(64, 60, 60, 1)",
                                  xs: "rgba(95, 95, 95, 1)",
                                },
                              }}
                            >
                              {child.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 300,
                                color: {
                                  md: "rgba(98, 98, 98, 1)",
                                  xs: "rgba(95, 95, 95, 1)",
                                },
                              }}
                            >
                              {capitalCase(
                                (user &&
                                  user.user &&
                                  user.user.notifications &&
                                  `${
                                    Object.keys(
                                      JSON.parse(user.user.notifications)[
                                        child.key
                                      ] || {}
                                    )
                                      .filter(
                                        (key) =>
                                          parseInt(
                                            JSON.parse(user.user.notifications)[
                                              child.key
                                            ][key],
                                            10
                                          ) === 1
                                      )
                                      .join(" | ") || "Disabled"
                                  }`) ||
                                  "Disabled"
                              )}
                            </Typography>
                          </Stack>
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                              setSelected({
                                title: child.title,
                                subtitle: child.subtitle,
                                values: ["email", "sms", "notification"],
                                value: child.key,
                              });
                              const notifications = JSON.parse(
                                (user &&
                                  user.user &&
                                  user.user.notifications) ||
                                  "{}"
                              );
                              setValues(
                                notifications[child.key] || {
                                  email: 0,
                                  sms: 0,
                                  notification: 0,
                                }
                              );
                              setIsEdit(true);
                            }}
                            sx={{
                              textDecoration: "underline",
                              fontWeight: { md: 300, xs: 500 },
                              fontSize: 14,
                            }}
                          >
                            Edit
                          </Button>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {isEdit && (
        <Edit
          open={isEdit}
          title={selected.title}
          subtitle={selected.subtitle}
          type={selected.value}
          handleClose={handleClose}
          values={values}
          setValue={setValues}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
