import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
// components
import { MIconButton } from "../../components/@material-extend";
import MenuPopover from "../../components/MenuPopover";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { ASSETS_PATH, BUSINESS_SWITCHES, BUSINESSES } from "../../utils/apis";
import { PATH_DASHBOARD } from "../../routes/paths";
import ToggleAccount from "./ToggleAccount";
import { isMobile } from "react-device-detect";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    icon: homeFill,
    linkTo: PATH_DASHBOARD.general.settings,
  },
  {
    label: "Notifications",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.settings,
  },
  {
    label: "Wallet",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.addListings,
  },
  {
    label: "Create a new listing",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.addListings,
  },
  {
    label: "Switch to travelling",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.settings,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const photo =
    user && user.user && user.user.photo
      ? `${ASSETS_PATH.users}${user.user.photo}`
      : "/static/placeholder.svg";
  const businessType = parseInt((user && user.user && user.user.type) || 0, 10);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          py: 0.8,
          px: 0.8,
          boxShadow: { md: "0px 0px 4px 1px rgba(0, 0, 0, 0.08)", xs: "none" },
          width: "fit-content",
          height: "fit-content",
          borderRadius: 10,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: 2,
              position: "absolute",
            },
          }),
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            sx={{ border: { md: "1px solid rgba(0, 0, 0, 0.3)", xs: "none" } }}
            alt={user && user.user ? user.user.fname : ""}
            src={photo}
          />
          <Stack
            sx={{ pr: 4, display: { md: "flex", xs: "none" } }}
            alignItems="start"
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                mb: 0,
              }}
            >
              {(user && user.user && user.user.fname) || ""}&nbsp;
              {(user && user.user && user.user.lname) || ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: 13,
                fontWeight: 400,
                color: "rgba(64, 60, 60, 1)",
                width: 1,
                textAlign: "left",
              }}
            >
              {(user &&
                user.user &&
                BUSINESSES[parseInt(user.user.type, 10)].name) ||
                ""}
            </Typography>
          </Stack>
          <KeyboardArrowDownOutlinedIcon
            sx={{
              color: "rgba(64, 60, 60, 1)",
              display: { md: "inline-block", xs: "none" },
            }}
          />
        </Stack>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user && user.user ? `${user.user.fname} ${user.user.lname}` : ""}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            Account ID: {user && user.user ? user.user.uid : ""}
          </Typography>
        </Box>
        {isMobile && BUSINESS_SWITCHES.includes(businessType) && (
          <Divider sx={{ my: 1 }} />
        )}
        {isMobile && BUSINESS_SWITCHES.includes(businessType) && (
          <ToggleAccount />
        )}
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option, index) => (
          <Box key={index}>
            {index % 2 === 0 && index !== 0 && (
              <Divider
                key={index}
                flexItem
                sx={{ borderColor: "rgba(0, 0, 0, 0.25)" }}
              />
            )}
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{
                typography: "body2",
                py: 1,
                px: 2.5,
                fontSize: 14,
                fontWeight: 400,
                color: index > 1 ? "rgba(4, 4, 4, 0.7)" : "rgba(4, 4, 4, 1)",
              }}
            >
              {option.label}
            </MenuItem>
          </Box>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="text"
            onClick={handleLogout}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: "rgba(4, 4, 4, 0.7)",
              justifyContent: "start",
            }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
