import { useState } from "react";
import { useLocation, useParams, Outlet } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { PATH_DASHBOARD } from "../../routes/paths";
import RoleBasedGuard from "../../guards/RoleBasedGuard";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  backgroundColor: "#f5faf4",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------
const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  if (Object.prototype.hasOwnProperty.call(params, "*")) {
    delete params["*"];
  }
  return location.pathname.split("/").length - 1 === 1
    ? location.pathname
    : Object.values(params).reduce(
        (path, param) => path.replace(`/${param}`, ""),
        location.pathname
      );
};
const EXCLUDENAV = [
  PATH_DASHBOARD.addListings,
  PATH_DASHBOARD.selectBusinessType,
  PATH_DASHBOARD.editListings,
];
export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const pathname = useBasePath();

  return (
    <RootStyle>
      {!EXCLUDENAV.includes(pathname) && (
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      )}
      {!EXCLUDENAV.includes(pathname) && (
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}
      <MainStyle
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: "102px",
          }),
          ...(EXCLUDENAV.includes(pathname) && {
            ml: "0px!important",
            pt: "0px!important",
            px: "0px!important",
            pb: "0px!important",
            backgroundColor: "#f5f5f5",
          }),
        }}
      >
        <RoleBasedGuard>
          <Outlet />
        </RoleBasedGuard>
      </MainStyle>
    </RootStyle>
  );
}
