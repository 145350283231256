import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { Switch, Stack, Typography } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const TITLES = [
  {
    labels: ["Hotels", "Shortlets"],
    types: [0, 3],
  },
  {
    labels: ["Events", "Attractions"],
    types: [1, 6],
  },
];
export default function ToggleAccount() {
  const { enqueueSnackbar } = useSnackbar();
  const { user, switchAccount } = useAuth();
  const businessType = parseInt((user && user.user && user.user.type) || 0, 10);
  const [current, setCurrent] = useState({});
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgZD0ibTEzIDJsMS41LjVMMTMgM3ptMSAxNmwxIDVoLTJ6bTAtMS41bDIgNi41aC00ek0xMiA2bDkgNXYySDN2LTJ6bS03LjUgN2gxNWMwIDQuMTY3IDEuNSAxMCAxLjUgMTBIM3MxLjUtNS44MzMgMS41LTEwWm0wIDBoMTVjMCA0LjE2NyAxLjUgMTAgMS41IDEwSDNzMS41LTUuODMzIDEuNS0xMFoiLz48L3N2Zz4=')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IndoaXRlIiBkPSJNMjEgMTdWOEg3djl6bTAtMTRhMiAyIDAgMCAxIDIgMnYxMmEyIDIgMCAwIDEtMiAySDdhMiAyIDAgMCAxLTItMlY1YTIgMiAwIDAgMSAyLTJoMVYxaDJ2Mmg4VjFoMnYyek0zIDIxaDE0djJIM2EyIDIgMCAwIDEtMi0yVjloMnptMTYtNmgtNHYtNGg0eiIvPjwvc3ZnPg==')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));
  const MaterialUIHotelSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IndoaXRlIiBkPSJNMy41IDIwLjE1NHYtMTNoNHYtNGg5djhoNHY5aC03di00aC0zdjR6bTEtMWgzdi0zaC0zem0wLTRoM3YtM2gtM3ptMC00aDN2LTNoLTN6bTQgNGgzdi0zaC0zem0wLTRoM3YtM2gtM3ptMC00aDN2LTNoLTN6bTQgOGgzdi0zaC0zem0wLTRoM3YtM2gtM3ptMC00aDN2LTNoLTN6bTQgMTJoM3YtM2gtM3ptMC00aDN2LTNoLTN6Ii8+PC9zdmc+')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0ibTEyLjU5NCAyMy4yNThsLS4wMTIuMDAybC0uMDcxLjAzNWwtLjAyLjAwNGwtLjAxNC0uMDA0bC0uMDcxLS4wMzZxLS4wMTYtLjAwNC0uMDI0LjAwNmwtLjAwNC4wMWwtLjAxNy40MjhsLjAwNS4wMmwuMDEuMDEzbC4xMDQuMDc0bC4wMTUuMDA0bC4wMTItLjAwNGwuMTA0LS4wNzRsLjAxMi0uMDE2bC4wMDQtLjAxN2wtLjAxNy0uNDI3cS0uMDA0LS4wMTYtLjAxNi0uMDE4bS4yNjQtLjExM2wtLjAxNC4wMDJsLS4xODQuMDkzbC0uMDEuMDFsLS4wMDMuMDExbC4wMTguNDNsLjAwNS4wMTJsLjAwOC4wMDhsLjIwMS4wOTJxLjAxOS4wMDUuMDI5LS4wMDhsLjAwNC0uMDE0bC0uMDM0LS42MTRxLS4wMDUtLjAxOS0uMDItLjAyMm0tLjcxNS4wMDJhLjAyLjAyIDAgMCAwLS4wMjcuMDA2bC0uMDA2LjAxNGwtLjAzNC42MTRxLjAwMS4wMTguMDE3LjAyNGwuMDE1LS4wMDJsLjIwMS0uMDkzbC4wMS0uMDA4bC4wMDMtLjAxMWwuMDE4LS40M2wtLjAwMy0uMDEybC0uMDEtLjAxeiIvPjxwYXRoIGZpbGw9IndoaXRlIiBkPSJNNSA1YTIgMiAwIDAgMSAyLTJoMTBhMiAyIDAgMCAxIDIgMnYxaDFhMSAxIDAgMSAxIDAgMnYxMWExIDEgMCAxIDEgMCAySDRhMSAxIDAgMSAxIDAtMlY4YTEgMSAwIDAgMSAwLTJoMXptMTIgMHYxSDdWNXptMSAzdjExaC0zLjV2LTIuNWEyLjUgMi41IDAgMCAwLTUgMFYxOUg2Vjh6bS02IDhhLjUuNSAwIDAgMSAuNS41VjE5aC0xdi0yLjVhLjUuNSAwIDAgMSAuNS0uNW0tNC0zYTEgMSAwIDAgMSAxIDF2MWExIDEgMCAxIDEtMiAwdi0xYTEgMSAwIDAgMSAxLTFtOCAwYTEgMSAwIDAgMSAuOTkzLjg4M0wxNyAxNHYxYTEgMSAwIDAgMS0xLjk5My4xMTdMMTUgMTV2LTFhMSAxIDAgMCAxIDEtMU04IDlhMSAxIDAgMCAxIC45OTMuODgzTDkgMTB2MWExIDEgMCAwIDEtMS45OTMuMTE3TDcgMTF2LTFhMSAxIDAgMCAxIDEtMW00IDBhMSAxIDAgMCAxIC45OTMuODgzTDEzIDEwdjFhMSAxIDAgMCAxLTEuOTkzLjExN0wxMSAxMXYtMWExIDEgMCAwIDEgMS0xbTQgMGExIDEgMCAwIDEgMSAxdjFhMSAxIDAgMSAxLTIgMHYtMWExIDEgMCAwIDEgMS0xIi8+PC9nPjwvc3ZnPg==')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const handleChange = async (event) => {
    try {
      const newType = current.types[event.target.checked ? 1 : 0];
      await switchAccount(newType);
      enqueueSnackbar(`Account switched successfully!`, {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setCurrent(TITLES.filter((title) => title.types.includes(businessType))[0]);
  }, [businessType]);

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 13, fontWeight: 500, color: "rgba(0, 0, 0, 1)" }}
        >
          {current && current.labels && current.labels[0]}
        </Typography>
        {((businessType === 0 || businessType === 3) && (
          <MaterialUIHotelSwitch
            sx={{ m: 1 }}
            onChange={handleChange}
            checked={
              (current && current.types && current.types[1] === businessType) ||
              false
            }
          />
        )) || (
          <MaterialUISwitch
            sx={{ m: 1 }}
            onChange={handleChange}
            checked={
              (current && current.types && current.types[1] === businessType) ||
              false
            }
          />
        )}
        <Typography
          variant="body1"
          sx={{ fontSize: 13, fontWeight: 500, color: "rgba(0, 0, 0, 1)" }}
        >
          {current && current.labels && current.labels[1]}
        </Typography>
      </Stack>
    </Stack>
  );
}
