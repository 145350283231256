import PropTypes from "prop-types";
// material
import { Box, Stack, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function Label({ color = "default", children, sx }) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center" sx={{ ...sx }}>
      <Box
        sx={{
          width: 10,
          height: 10,
          backgroundColor: `${color}.main`,
          borderRadius: "50%",
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontSize: { md: 14, xs: 12 },
          fontWeight: 400,
          color: "rgba(64, 60, 60, 1)",
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
}

Label.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
};
