import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  IconButton,
  FormControlLabel,
  Autocomplete,
  Tabs,
  Tab,
  Divider,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useAuth from "../../../hooks/useAuth";
import { COUNTRIES } from "../../../utils/apis";
// utils
import Close from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import { Icon } from "@iconify/react";

const MOBILE_FIELDS = [
  { title: "Request your personal data", value: "personal" },
  { title: "Delete your account", value: "delete" },
];
const REASONS = [
  "I want too know what Afritas  know about me",
  "I want too move my data too another service",
  "I plan to delete or deactivate my account soon",
  "I need to access specific data for legal reasons",
  "Other",
];

Edit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDeactivate: PropTypes.func,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
};
function Edit({ open, isLoading, handleSave, handleClose, handleDeactivate }) {
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ position: "relative" }}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 1, pt: 5 }}>
        <Icon icon="emojione:warning" style={{ width: 35, height: 35 }} />
        <DialogTitle
          sx={{
            fontSize: { md: 20, xs: 16 },
            fontWeight: 500,
            color: "rgba(64, 60, 60, 1)",
            pl: 0,
            pt: 1,
          }}
        >
          Delete account?
        </DialogTitle>
        <DialogContentText
          sx={{
            mb: 5,
            fontSize: { md: 14, xs: 13 },
            fontWeight: 400,
            color: "rgba(64, 60, 60, 1)",
          }}
        >
          Requesting deletion of your account means that you will no longer be
          able to use your Afritas account, and your account will be permanently
          closed.
          <br />
          <br />
          If you’d prefer to close your account temporarily, deactivating your
          Afritas account is a better option.
        </DialogContentText>
        <LoadingButton
          variant="outlined"
          fullWidth
          onClick={handleDeactivate}
          loading={isLoading}
          sx={{
            borderColor: "rgba(0, 0, 0, 1)",
            color: "rgba(62, 61, 61, 1)",
            fontSize: 13,
            fontWeight: 500,
            py: 1,
          }}
        >
          Deactivate instead
        </LoadingButton>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end", pt: "4rem!important" }}>
        <LoadingButton
          onClick={handleSave}
          loading={isLoading}
          sx={{
            px: 4,
            py: 1.2,
            boxShadow: "none",
            fontSize: 13,
            bgcolor: "rgba(49, 49, 49, 1)",
            fontWeight: 500,
            color: "common.white",
          }}
          variant="contained"
        >
          Delete account
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function Policy() {
  const { enqueueSnackbar } = useSnackbar();
  const { updateSettings } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [selectedTab, setSelectedTab] = useState("");

  const handleClose = () => {
    setIsEdit(false);
    setValues({});
  };
  const handleSave = async (type) => {
    try {
      if (selectedTab === "personal") {
        setLoading(true);
        await updateSettings(
          {
            country: values.country || "",
            format: values.format || "pdf",
            reason: values.reason,
          },
          "personal_data"
        );
        enqueueSnackbar(
          "Your personal data has been sent to your email address!",
          {
            variant: "success",
          }
        );
        handleClose();
      }
      if (selectedTab === "delete") {
        setLoading(true);
        await updateSettings(
          {
            country: values.country || "",
            reason: values.reason,
          },
          type || "delete_account"
        );
        enqueueSnackbar(
          type === "deactivate"
            ? "Your account has been deactivated!"
            : "Your account deletion has been requested!",
          {
            variant: "success",
          }
        );
        handleClose();
      }
    } catch (err) {
      enqueueSnackbar(err.message || err, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!isMobile) {
      setSelectedTab("personal");
    }
  }, []);
  return (
    <>
      <Grid container spacing={{ md: 3, xs: 0 }}>
        <Grid item xs={12} md={8}>
          {!isMobile && (
            <Tabs
              sx={{
                mb: 5,
                "& button": {
                  color: "rgba(98, 98, 98, 1)!important",
                  fontSize: 14,
                  fontWeight: 500,
                },
                "& .Mui-selected": {
                  color: "rgba(65, 177, 26, 1)!important",
                },
              }}
              onChange={(e, v) => setSelectedTab(v)}
              value={selectedTab}
            >
              <Tab value="personal" label="Request your personal data" />
              <Tab value="delete" label="Delete your account" />
            </Tabs>
          )}
          {isMobile && !selectedTab && (
            <Stack
              spacing={0}
              sx={{
                bgcolor: "rgba(255, 255, 255, 1)",
                boxShadow: "0px 0px 14.8px 10px rgba(0, 0, 0, 0.01)",
                borderRadius: "13px",
              }}
              divider={
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{ borderColor: "rgba(0, 0, 0, 0.1)" }}
                />
              }
            >
              {MOBILE_FIELDS.map((item, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ p: 2 }}
                  key={index}
                  onClick={() => {
                    setSelectedTab(item.value);
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "rgba(95, 95, 95, 1)",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <IconButton
                    variant="text"
                    size="small"
                    onClick={() => {
                      setSelectedTab(item.value);
                    }}
                  >
                    <Icon
                      icon="lsicon:right-outline"
                      style={{
                        width: 24,
                        height: 24,
                        color: "rgba(65, 177, 26, 1)",
                      }}
                    />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          )}
          {selectedTab === "personal" && (
            <Stack
              spacing={{ md: 2, xs: 1 }}
              sx={{
                pb: { md: 20, xs: 1 },
                px: { md: 0, xs: 2 },
                width: { md: 600, xs: 1 },
              }}
              divider={
                isMobile && <Divider flexItem orientation="horizontal" />
              }
            >
              <Stack
                direction="column"
                alignItems="start"
                justifyContent="space-between"
                spacing={{ md: 3, xs: 2 }}
                sx={{
                  py: 2,
                }}
              >
                <Stack alignItems="start" spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                    }}
                  >
                    Request your personal data
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 13,
                      fontWeight: 300,
                      color: "rgba(95, 95, 95, 1)",
                    }}
                  >
                    Before we get you a copy of your data, we will just need
                    your to answer a few questions.
                  </Typography>
                </Stack>
                <Card
                  sx={{
                    py: { md: 1, xs: 3 },
                    px: { md: 3, xs: 2 },
                    boxShadow: {
                      md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                      xs: "none",
                    },
                    width: 1,
                    bgcolor: { md: "transparent", xs: "common.white" },
                    borderRadius: 2,
                  }}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(28, 28, 28, 1)",
                      }}
                    >
                      Where do you reside?
                    </Typography>
                    <FormControlLabel
                      label="Country"
                      labelPlacement="top"
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 300,
                          color: "rgba(64, 60, 60, 0.7)",
                          pt: 0.5,
                          pl: 2,
                        },
                        alignItems: "start",
                        border: {
                          md: "1.5px solid rgba(0, 0, 0, 1)",
                          xs: "1px solid rgba(0, 0, 0, 0.5)",
                        },
                        borderRadius: 1,
                        mx: 0,
                      }}
                      control={
                        <Autocomplete
                          fullWidth
                          options={COUNTRIES}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              bgcolor: "rgba(248, 250, 252, 1)",
                              py: 0,
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            "& input": {
                              py: 0.5,
                              fontSize: 14,
                              fontWeight: 500,
                              color: {
                                md: "rgba(64, 60, 60, 1)",
                                xs: "rgba(95, 95, 95, 1)",
                              },
                            },
                          }}
                          getOptionLabel={(option) => option.name || ""}
                          value={
                            COUNTRIES.filter(
                              (country) => country.code === values.country
                            )[0]
                          }
                          name="country"
                          onChange={(event, newValue) => {
                            if (newValue.name) {
                              setValues((prev) => ({
                                ...prev,
                                country: newValue.code,
                              }));
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.code === value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select your country"
                            />
                          )}
                        />
                      }
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(28, 28, 28, 1)",
                        pt: 2,
                      }}
                    >
                      In what format do you want your data?
                    </Typography>
                    <FormControlLabel
                      label="Select Format"
                      labelPlacement="top"
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 300,
                          color: "rgba(64, 60, 60, 0.7)",
                          pt: 0.5,
                          pl: 2,
                        },
                        alignItems: "start",
                        border: {
                          md: "1.5px solid rgba(0, 0, 0, 1)",
                          xs: "1px solid rgba(0, 0, 0, 0.5)",
                        },
                        borderRadius: 1,
                        mx: 0,
                      }}
                      control={
                        <TextField
                          size="small"
                          fullWidth
                          select
                          value={values.format || ""}
                          onChange={(e) =>
                            setValues((prev) => ({
                              ...prev,
                              format: e.target.value || "",
                            }))
                          }
                          sx={{
                            "& fieldset": {
                              border: "none",
                            },
                            fontSize: 13,
                            fontWeight: 400,
                            color: "rgba(64, 60, 60, 1)",
                            "& .MuiSelect-select": {
                              fontSize: 13,
                              fontWeight: 400,
                              color: "rgba(64, 60, 60, 1)",
                            },
                            "& .MuiSelect-icon": {
                              color: "rgba(64, 60, 60, 1)",
                            },
                          }}
                        >
                          {[
                            {
                              name: "Portable Document Format (PDF)",
                              value: "pdf",
                            },
                            { name: "Excel worksheet", value: "excel" },
                          ].map((item, index) => (
                            <MenuItem
                              key={index}
                              value={(
                                (typeof item === "string" && item) ||
                                item.value
                              ).toLocaleLowerCase()}
                            >
                              {(typeof item === "string" && item) || item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(28, 28, 28, 1)",
                        pt: 2,
                      }}
                    >
                      Why are your requesting for your data?
                    </Typography>
                    <FormControlLabel
                      label="Select reason (optional)"
                      labelPlacement="top"
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 300,
                          color: "rgba(64, 60, 60, 0.7)",
                          pt: 0.5,
                          pl: 2,
                        },
                        alignItems: "start",
                        border: {
                          md: "1.5px solid rgba(0, 0, 0, 1)",
                          xs: "1px solid rgba(0, 0, 0, 0.5)",
                        },
                        borderRadius: 1,
                        mx: 0,
                      }}
                      control={
                        <TextField
                          size="small"
                          fullWidth
                          select
                          value={values.reason || ""}
                          onChange={(e) =>
                            setValues((prev) => ({
                              ...prev,
                              reason: e.target.value || "",
                            }))
                          }
                          sx={{
                            "& fieldset": {
                              border: "none",
                            },
                            fontSize: 13,
                            fontWeight: 400,
                            color: "rgba(64, 60, 60, 1)",
                            "& .MuiSelect-select": {
                              fontSize: 13,
                              fontWeight: 400,
                              color: "rgba(64, 60, 60, 1)",
                            },
                            "& .MuiSelect-icon": {
                              color: "rgba(64, 60, 60, 1)",
                            },
                          }}
                        >
                          {REASONS.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={(
                                (typeof item === "string" && item) ||
                                item.value
                              ).toLocaleLowerCase()}
                              sx={{
                                wordWrap: "normal",
                                fontSize: 13,
                                fontWeight: 400,
                                color: "rgba(64, 60, 60, 1)",
                              }}
                            >
                              {(typeof item === "string" && item) || item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    />
                  </Stack>
                </Card>
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    pt: 4,
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    sx={{
                      bgcolor: "rgba(105, 105, 105, 1)",
                      fontSize: 13,
                      fontWeight: 500,
                      boxShadow: "none",
                    }}
                    loading={isLoading}
                    fullWidth={isMobile}
                    disabled={!(values.country && values.format)}
                    onClick={handleSave}
                  >
                    Request Data
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          )}
          {selectedTab === "delete" && (
            <Stack
              spacing={{ md: 2, xs: 1 }}
              sx={{
                pb: { md: 20, xs: 1 },
                px: { md: 0, xs: 2 },
                width: { md: 600, xs: 1 },
              }}
              divider={
                isMobile && <Divider flexItem orientation="horizontal" />
              }
            >
              <Stack
                direction="column"
                alignItems="start"
                justifyContent="space-between"
                spacing={{ md: 3, xs: 2 }}
                sx={{
                  py: 2,
                }}
              >
                <Stack alignItems="start" spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                    }}
                  >
                    Delete your account
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 13,
                      fontWeight: 300,
                      color: "rgba(95, 95, 95, 1)",
                      bgcolor: { md: "transparent", xs: "common.white" },
                      borderRadius: { md: 0, xs: 2 },
                      p: { md: 0, xs: 2 },
                    }}
                  >
                    We're sorry to see you go. If you wish to delete your
                    account, please follow the steps below. Once your account is
                    deleted, all your data will be permanently removed, and you
                    won't be able to recover it.
                    <br />
                    <br />
                    We're sorry to see you go. If you wish to delete your
                    account, please follow the steps below. Once your account is
                    deleted, all your data will be permanently removed, and you
                    won't be able to recover it.
                    <br />
                    <br />
                    We're sorry to see you go. If you wish to delete your
                    account, please follow the steps below. Once your account is
                    deleted, all your data will be permanently removed, and you
                    won't be able to recover it.
                  </Typography>
                </Stack>
                <Card
                  sx={{
                    py: { md: 1, xs: 3 },
                    px: { md: 3, xs: 2 },
                    boxShadow: {
                      md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                      xs: "none",
                    },
                    bgcolor: "transparent",
                    width: 1,
                  }}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(28, 28, 28, 1)",
                      }}
                    >
                      Where do you reside?
                    </Typography>
                    <FormControlLabel
                      label="Country"
                      labelPlacement="top"
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 300,
                          color: "rgba(64, 60, 60, 0.7)",
                          pt: 0.5,
                          pl: 2,
                        },
                        alignItems: "start",
                        border: {
                          md: "1.5px solid rgba(0, 0, 0, 1)",
                          xs: "1px solid rgba(0, 0, 0, 0.5)",
                        },
                        borderRadius: 1,
                        mx: 0,
                      }}
                      control={
                        <Autocomplete
                          fullWidth
                          options={COUNTRIES}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              bgcolor: "rgba(248, 250, 252, 1)",
                              py: 0,
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            "& input": {
                              py: 0.5,
                              fontSize: 14,
                              fontWeight: 500,
                              color: {
                                md: "rgba(64, 60, 60, 1)",
                                xs: "rgba(95, 95, 95, 1)",
                              },
                            },
                          }}
                          getOptionLabel={(option) => option.name || ""}
                          value={
                            COUNTRIES.filter(
                              (country) => country.code === values.country
                            )[0]
                          }
                          name="country"
                          onChange={(event, newValue) => {
                            if (newValue.name) {
                              setValues((prev) => ({
                                ...prev,
                                country: newValue.code,
                              }));
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.code === value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select your country"
                            />
                          )}
                        />
                      }
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(28, 28, 28, 1)",
                        pt: 2,
                      }}
                    >
                      Why are you deleting your account?
                    </Typography>
                    <FormControlLabel
                      label="Select reason (optional)"
                      labelPlacement="top"
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 300,
                          color: "rgba(64, 60, 60, 0.7)",
                          pt: 0.5,
                          pl: 2,
                        },
                        alignItems: "start",
                        border: {
                          md: "1.5px solid rgba(0, 0, 0, 1)",
                          xs: "1px solid rgba(0, 0, 0, 0.5)",
                        },
                        borderRadius: 1,
                        mx: 0,
                      }}
                      control={
                        <TextField
                          size="small"
                          fullWidth
                          select
                          value={values.reason || ""}
                          onChange={(e) =>
                            setValues((prev) => ({
                              ...prev,
                              reason: e.target.value || "",
                            }))
                          }
                          sx={{
                            "& fieldset": {
                              border: "none",
                            },
                            fontSize: 13,
                            fontWeight: 400,
                            color: "rgba(64, 60, 60, 1)",
                            "& .MuiSelect-select": {
                              fontSize: 13,
                              fontWeight: 400,
                              color: "rgba(64, 60, 60, 1)",
                            },
                            "& .MuiSelect-icon": {
                              color: "rgba(64, 60, 60, 1)",
                            },
                          }}
                        >
                          {REASONS.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={(
                                (typeof item === "string" && item) ||
                                item.value
                              ).toLocaleLowerCase()}
                              sx={{
                                wordWrap: "normal",
                                fontSize: 13,
                                fontWeight: 400,
                                color: "rgba(64, 60, 60, 1)",
                              }}
                            >
                              {(typeof item === "string" && item) || item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    />
                  </Stack>
                </Card>
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    pt: 4,
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    sx={{
                      bgcolor: "rgba(105, 105, 105, 1)",
                      fontSize: 13,
                      fontWeight: 500,
                      boxShadow: "none",
                    }}
                    loading={isLoading}
                    fullWidth={isMobile}
                    disabled={!values.country}
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  >
                    Next
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          )}
        </Grid>
      </Grid>
      {isEdit && (
        <Edit
          open={isEdit}
          handleClose={handleClose}
          handleSave={() => handleSave("")}
          handleDeactivate={() => handleSave("deactivate")}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
