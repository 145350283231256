import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useState } from "react";
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Button,
  IconButton,
  FormControlLabel,
  Autocomplete,
  InputAdornment,
  Divider,
  Link,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { LoadingButton } from "@mui/lab";
// hooks
import useAuth from "../../../hooks/useAuth";
import { COMPLIANCE_TYPE, COUNTRIES, LGAS, STATES } from "../../../utils/apis";
// utils
import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const FIELDS = [
  {
    title: "Legal name",
    subtitle: "Make sure this matches the name on your government ID",
    values: ["fname", "lname"],
    value: "name",
    isOptional: false,
  },
  {
    title: "Preferred name",
    subtitle: "Enter a preferred name",
    values: ["username"],
    value: "username",
    isOptional: true,
  },
  {
    title: "Email address",
    subtitle: "Use an address you'll always have access to.",
    values: ["email"],
    value: "email",
    isOptional: false,
  },
  {
    title: "Phone number",
    subtitle: "Enter a new phone number",
    values: ["country", "phone"],
    value: "phone",
    isOptional: false,
  },
  {
    title: "Government ID",
    values: ["idType", "idNumber"],
    value: "id",
    isOptional: true,
  },
  {
    title: "Address",
    subtitle: "Enter a verifiable address",
    values: ["address", "city", "state"],
    value: "address",
    isOptional: false,
  },
];

Edit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setValue: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
};
function Edit({
  open,
  title,
  subtitle,
  type,
  values,
  setValue,
  isLoading,
  handleSave,
  handleClose,
}) {
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ position: "relative" }}>
        <DialogTitle
          sx={{
            fontSize: { md: 20, xs: 16 },
            fontWeight: 500,
            color: { md: "rgb(0,0,0)", xs: "rgba(64, 60, 60, 1)" },
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 1 }}>
        <DialogContentText
          sx={{ mb: 5, fontSize: { md: 14, xs: 12 }, fontWeight: 400 }}
        >
          {subtitle}
        </DialogContentText>
        {type === "name" && (
          <Stack direction={{ md: "row", xs: "column" }} spacing={2}>
            <FormControlLabel
              label="First Name on ID"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="fname"
                  value={values.fname || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      fname: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              label="Last Name on ID"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="lname"
                  value={values.lname || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      lname: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "username" && (
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <FormControlLabel
              label="Preferred Name"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
                width: 1,
              }}
              control={
                <TextField
                  fullWidth
                  name="username"
                  value={values.username || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      username: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "email" && (
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <FormControlLabel
              label="Email Address"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
                width: 1,
              }}
              control={
                <TextField
                  fullWidth
                  name="email"
                  value={values.email || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      email: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "phone" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="Country"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={COUNTRIES}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                  getOptionLabel={(option) =>
                    option.name
                      ? `${option.name || ""} (${option.dial_code || ""})`
                      : ""
                  }
                  value={
                    COUNTRIES.filter(
                      (country) => country.code === values.country
                    )[0]
                  }
                  name="country"
                  onChange={(event, newValue) => {
                    if (newValue.name) {
                      setValue((prev) => ({ ...prev, country: newValue.code }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select your country" />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Phone Number"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  autoComplete="tel"
                  type="tel"
                  name="phone"
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      phone: e.target.value || "",
                    }))
                  }
                  value={values.phone || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          COUNTRIES.filter(
                            (country) => country.code === values.country
                          )[0].dial_code
                        }
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "address" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="State of Residence"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={STATES}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                  getOptionLabel={(option) => option || ""}
                  value={values.state || ""}
                  name="state"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue((prev) => ({
                        ...prev,
                        state: newValue,
                        city: "",
                      }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select state of residence"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="City / Local Government Area"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={
                    values.state
                      ? LGAS.filter((item) => item.state === values.state)[0]
                          .lgas || []
                      : []
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                  getOptionLabel={(option) => option || ""}
                  value={values.city || ""}
                  name="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue((prev) => ({ ...prev, city: newValue }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select city of residence"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Address"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="address"
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      address: e.target.value || "",
                    }))
                  }
                  value={values.address || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(9, 9, 20, 1)",
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start" }}>
        <LoadingButton
          onClick={handleSave}
          loading={isLoading}
          sx={{
            px: 4,
            py: 1.2,
            boxShadow: "none",
            fontSize: 16,
            fontWeight: 500,
          }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function General() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, updateSettings } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [values, setValues] = useState({});

  const handleClose = () => {
    setIsEdit(false);
    setSelected({});
    setValues({});
  };
  const handleSave = async () => {
    try {
      const isEmpty =
        Object.keys(values).filter((key) => !values[key]).length > 0;
      if (selected.isOptional || !isEmpty) {
        setLoading(true);
        await updateSettings(values, selected.value);
        enqueueSnackbar("Settings updated successfully!", {
          variant: "success",
        });
        handleClose();
      } else {
        throw new Error("Please fill all required fields to proceed.");
      }
    } catch (err) {
      enqueueSnackbar(err.message || err, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stack
            spacing={2}
            sx={{
              bgcolor: { md: "transparent", xs: "common.white" },
              py: { md: 0, xs: 2 },
              borderRadius: { md: 0, xs: 2 },
            }}
            divider={
              <Divider
                flexItem
                orientation="horizontal"
                sx={{
                  borderColor: "rgba(0, 0, 0, 0.1)",
                  display: { md: "none", xs: "block" },
                }}
              />
            }
          >
            {FIELDS.map((item, index) => (
              <Card
                key={index}
                sx={{
                  py: { md: 1, xs: 0 },
                  px: { md: 3, xs: 0 },
                  pl: { md: 2, xs: 2 },
                  boxShadow: {
                    md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                    xs: "none",
                  },
                  borderRadius: 1,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack alignItems="start" spacing={0.5}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 300,
                        color: "rgba(98, 98, 98, 1)",
                      }}
                    >
                      {(user &&
                        user.user &&
                        item.values
                          .map((value) =>
                            value === "idType" && user.user[value]
                              ? COMPLIANCE_TYPE.filter(
                                  (type) => type.value === user.user[value]
                                )[0].label
                              : user.user[value]
                          )
                          .filter((value) => value !== "")
                          .join(" ")) ||
                        `Not Provided ${
                          (item.isOptional && "(Optional)") || ""
                        }`}
                    </Typography>
                  </Stack>
                  <Button
                    variant="text"
                    startIcon={
                      !isMobile && <CreateOutlinedIcon fontSize="small" />
                    }
                    size="small"
                    onClick={() => {
                      if (item.value === "id") {
                        navigate("/settings/id");
                      } else {
                        setSelected(item);
                        setValues(
                          item.values.reduce((prev, current) => {
                            prev[current] = user.user[current] || "";
                            return prev;
                          }, {})
                        );
                        setIsEdit(true);
                      }
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontWeight: { md: 300, xs: 500 },
                      fontSize: 14,
                    }}
                  >
                    {user &&
                    user.user &&
                    item.values
                      .map((value) => user.user[value])
                      .filter((value) => value !== "")
                      .join(" ")
                      ? "Edit"
                      : "Add"}
                  </Button>
                </Stack>
              </Card>
            ))}
          </Stack>
          {isMobile && (
            <Stack
              direction="row"
              sx={{ pt: 5 }}
              divider={<span>&nbsp;&middot;&nbsp;</span>}
              alignItems="center"
              justifyContent="center"
            >
              <Link
                variant="body2"
                underline="always"
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "rgba(0, 0, 0, 1)",
                }}
                href="#"
              >
                Help & Support
              </Link>
              <Link
                variant="body2"
                underline="always"
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "rgba(0, 0, 0, 1)",
                }}
                href="#"
              >
                Terms of Service
              </Link>
              <Link
                variant="body2"
                underline="always"
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "rgba(0, 0, 0, 1)",
                }}
                href="#"
              >
                Privacy Policy
              </Link>
            </Stack>
          )}
        </Grid>
      </Grid>
      {isEdit && (
        <Edit
          open={isEdit}
          title={selected.title}
          subtitle={selected.subtitle}
          type={selected.value}
          handleClose={handleClose}
          values={values}
          setValue={setValues}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
