import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Button,
  IconButton,
  FormControlLabel,
  Autocomplete,
  InputAdornment,
  OutlinedInput,
  Radio,
  Divider,
} from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { LoadingButton } from "@mui/lab";
// hooks
import useAuth from "../../../hooks/useAuth";
import {
  COUNTRIES,
  DEVICE_STATUS,
  DEVICE_TYPES,
  LGAS,
  STATES,
} from "../../../utils/apis";
// utils
import Close from "@mui/icons-material/Close";
import { fDateTime, fToNow } from "../../../utils/formatTime";
import Label from "../../../components/Label";

Edit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setValue: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
};
function Edit({
  open,
  title,
  subtitle,
  type,
  values,
  setValue,
  isLoading,
  handleSave,
  handleClose,
}) {
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ position: "relative" }}>
        <DialogTitle sx={{ fontSize: 20, fontWeight: 500 }}>
          {title}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 1 }}>
        <DialogContentText sx={{ mb: 5, fontSize: 14, fontWeight: 400 }}>
          {subtitle}
        </DialogContentText>
        {type === "password" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="Current Password"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="cpassword"
                  type="password"
                  value={values.cpassword || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      cpassword: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(64, 60, 60, 1)",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              label="New Password"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="password"
                  type="password"
                  value={values.password || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      password: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(64, 60, 60, 1)",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              label="Confirm Password"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="npassword"
                  type="password"
                  value={values.npassword || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      npassword: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(64, 60, 60, 1)",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "username" && (
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <FormControlLabel
              label="Preferred Name"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
                width: 1,
              }}
              control={
                <TextField
                  fullWidth
                  name="username"
                  value={values.username || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      username: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(64, 60, 60, 1)",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "email" && (
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <FormControlLabel
              label="Email Address"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
                width: 1,
              }}
              control={
                <TextField
                  fullWidth
                  name="email"
                  value={values.email || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      email: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(64, 60, 60, 1)",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "phone" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="Country"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={COUNTRIES}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "rgba(64, 60, 60, 1)",
                    },
                  }}
                  getOptionLabel={(option) =>
                    option.name
                      ? `${option.name || ""} (${option.dial_code || ""})`
                      : ""
                  }
                  value={
                    COUNTRIES.filter(
                      (country) => country.code === values.country
                    )[0]
                  }
                  name="country"
                  onChange={(event, newValue) => {
                    if (newValue.name) {
                      setValue((prev) => ({ ...prev, country: newValue.code }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select your country" />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Phone Number"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  autoComplete="tel"
                  type="tel"
                  name="phone"
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      phone: e.target.value || "",
                    }))
                  }
                  value={values.phone || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          COUNTRIES.filter(
                            (country) => country.code === values.country
                          )[0].dial_code
                        }
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(9, 9, 20, 1)",
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "address" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="State of Residence"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={STATES}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "rgba(64, 60, 60, 1)",
                    },
                  }}
                  getOptionLabel={(option) => option || ""}
                  value={values.state || ""}
                  name="state"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue((prev) => ({
                        ...prev,
                        state: newValue,
                        city: "",
                      }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select state of residence"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="City / Local Government Area"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={
                    values.state
                      ? LGAS.filter((item) => item.state === values.state)[0]
                          .lgas || []
                      : []
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "rgba(64, 60, 60, 1)",
                    },
                  }}
                  getOptionLabel={(option) => option || ""}
                  value={values.city || ""}
                  name="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue((prev) => ({ ...prev, city: newValue }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select city of residence"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Address"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="address"
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      address: e.target.value || "",
                    }))
                  }
                  value={values.address || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(9, 9, 20, 1)",
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start" }}>
        <LoadingButton
          onClick={handleSave}
          loading={isLoading}
          color={
            (type === "device" && "error") ||
            (type === "account" && "error") ||
            "primary"
          }
          sx={{
            px: 4,
            py: 1.2,
            boxShadow: "none",
            fontSize: 16,
            fontWeight: 500,
          }}
          variant="contained"
        >
          {(type === "account" && "Deactivate Account") ||
            (type === "device" && "Proceed") ||
            (type === "password" && "Update Password") ||
            "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const LENGTH = 6;
const clamp = (min, max, val) => Math.max(min, Math.min(val, max));
function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(
      0,
      object.target.maxLength
    ));
  }
  return false;
}
const OPTIONS = [
  {
    method: "sms",
    name: "Text message (SMS)",
    message: "We will text you a code",
    icon: "material-symbols:chat-outline",
  },
  {
    method: "whatsapp",
    name: "WhatsApp",
    message: "We will send a code over wifi",
    icon: "ic:outline-whatsapp",
  },
  {
    method: "call",
    name: "Phone call",
    message: "We will call you with a code",
    icon: "material-symbols:call-outline",
  },
];

PINVerify.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setValue: PropTypes.func,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
  inputRefs: PropTypes.any,
};
function PINVerify({
  open,
  values,
  isLoading,
  handleSave,
  handleClose,
  inputRefs,
  handleKeyPress,
  setValue,
}) {
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: "relative",
          borderBottom: "1px solid rgba(16, 16, 16, 0.1)",
          pb: 1,
        }}
      >
        <DialogTitle
          sx={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}
        >
          {(values.isVerified && "Set Your PIN") ||
            (values.isSent && "Confirm your number") ||
            "2-Step authentication"}
        </DialogTitle>
        <IconButton
          onClick={() => {
            if (values.isSent) {
              setValue((prev) => ({ ...prev, isSent: false }));
            } else {
              handleClose();
            }
          }}
          sx={{ position: "absolute", top: 15, left: 10 }}
        >
          {(values.isSent && (
            <KeyboardArrowLeftOutlinedIcon
              sx={{ color: "rgba(64, 60, 60, 1)" }}
            />
          )) || <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />}
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 1 }}>
        {(values.isVerified && (
          <DialogContentText
            sx={{ mb: 0, mt: 5, fontSize: 14, fontWeight: 400 }}
          >
            Enter your Preferred PIN:
          </DialogContentText>
        )) ||
          (values.isSent && (
            <DialogContentText
              sx={{ mb: 0, mt: 5, fontSize: 14, fontWeight: 400 }}
            >
              Enter the code we sent over{" "}
              {
                OPTIONS.filter((option) => option.method === values.method)[0]
                  .name
              }{" "}
              to{" "}
              {
                COUNTRIES.filter(
                  (country) => country.code === values.country
                )[0].dial_code
              }
              &nbsp;{values.phone}:
            </DialogContentText>
          ))}
        {values.isSent && (
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexGrow: 1, flexShrink: 0, width: 1, pt: 1 }}
          >
            <Stack sx={{ width: 1 }} spacing={2}>
              <Stack
                direction="row"
                spacing={0}
                justifyContent="start"
                alignItems="center"
                sx={{
                  bgcolor: "rgba(248, 250, 252, 1)",
                  border: "1px solid rgba(203, 213, 225, 1)",
                  borderRadius: 1,
                  p: 0.5,
                  width: "fit-content",
                }}
              >
                {(!values.isVerified
                  ? ["code1", "code2", "code3", "code4", "code5", "code6"]
                  : ["code1", "code2", "code3", "code4"]
                ).map((item, index) => (
                  <OutlinedInput
                    key={item}
                    type="number"
                    placeholder="-"
                    onInput={maxLength}
                    name={item}
                    value={
                      values.isVerified
                        ? values.pin[index] || ""
                        : values.code[index] || ""
                    }
                    onChange={(e) => {
                      if (values.isVerified) {
                        setValue((prev) => ({
                          ...prev,
                          pin: prev.pin.map((c, i) =>
                            i === index ? e.target.value || "" : c
                          ),
                        }));
                      } else {
                        setValue((prev) => ({
                          ...prev,
                          code: prev.code.map((c, i) =>
                            i === index ? e.target.value || "" : c
                          ),
                        }));
                      }
                    }}
                    inputRef={(ref) => (inputRefs.current[index] = ref)}
                    autoFocus={Boolean(index === 0)}
                    inputProps={{
                      onChange: handleKeyPress(index),
                      onKeyPress: handleKeyPress(index),
                      maxLength: 1,
                      sx: {
                        p: 0,
                        textAlign: "center",
                        width: { xs: 36, sm: 56 },
                        height: { xs: 36, sm: 56 },
                        color: "rgba(0, 0, 0, 1)",
                      },
                    }}
                    sx={{ "& fieldset": { border: "none" } }}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
        {!values.isSent && (
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexGrow: 1, flexShrink: 0, width: 1, pt: 2 }}
          >
            <Stack sx={{ width: 1 }} spacing={1}>
              <Typography
                variant="body1"
                sx={{
                  color: "#403C3C",
                  fontSize: 20,
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                Let us know it's really you
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "rgba(64, 60, 60, 1)",
                  fontSize: 14,
                  fontWeight: 400,
                  textAlign: "start",
                }}
              >
                Choose how to get a verification code.
              </Typography>
              <Stack
                spacing={4}
                justifyContent="start"
                alignItems="start"
                sx={{ py: 4 }}
              >
                {OPTIONS.map((item, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: 1 }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Icon
                        icon={item.icon}
                        width={22}
                        height={22}
                        style={{ color: "rgba(64, 60, 60, 1)" }}
                      />
                      <Stack>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color: "rgba(64, 60, 60, 1)",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 13,
                            fontWeight: 300,
                            color: "rgba(64, 60, 60, 1)",
                          }}
                        >
                          {item.message}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Radio
                      value={item.method}
                      checked={values.method === item.method}
                      onChange={() => {
                        setValue((prev) => ({ ...prev, method: item.method }));
                      }}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: values.isSent ? "end" : "start" }}>
        <LoadingButton
          onClick={handleSave}
          loading={isLoading}
          fullWidth={!values.isSent}
          sx={{
            px: 4,
            py: 1.2,
            boxShadow: "none",
            fontSize: 16,
            fontWeight: 500,
            ...(values.isSent
              ? {
                  color: "rgba(64, 60, 60, 1)",
                  bgcolor: "rgba(248, 250, 252, 1)",
                  border: "1px solid rgba(203, 213, 225, 1)",
                  "&:hover": {
                    bgcolor: "rgba(248, 250, 252, 0.6)",
                    border: "1px solid rgba(203, 213, 225, 0.6)",
                  },
                }
              : {}),
          }}
          variant="contained"
        >
          {values.isSent ? "Continue" : "Resend code"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default function Security() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    user,
    updateSettings,
    sendVerifyCode,
    verifyAccount,
    getDevices,
    logoutDevice,
    logout,
  } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [devices, setDevices] = useState([]);
  const [values, setValues] = useState({});
  const inputRefs = useRef([]);
  const [data] = useState([...Array(LENGTH).keys()]);

  const handleKeyPress = (index) => () => {
    let nextIndex = clamp(0, data.length - 1, index + 1);
    const current = inputRefs.current[index];
    setTimeout(() => {
      if (current.value === "") {
        // go backwards
        nextIndex = index - 1 < 0 ? 0 : index - 1;
      }
      current.blur();
      inputRefs.current[nextIndex].focus();
    }, 100);
  };

  const handleClose = () => {
    setIsEdit(false);
    setIsPin(false);
    setSelected({});
    setValues({});
  };
  const handleSave = async () => {
    try {
      if (isPin) {
        if (values.method) {
          if (values.isSent) {
            if (values.isVerified) {
              const emptyPIN = values.pin.filter((c) => c === "");
              if (emptyPIN.length === 0) {
                const pin = values.pin.join("");
                setLoading(true);
                await updateSettings({ pin }, "pin");
                enqueueSnackbar("Settings updated successfully!", {
                  variant: "success",
                });
                handleClose();
              } else {
                throw new Error("Please enter a valid PIN to proceed.");
              }
            } else {
              const emptyCode = values.code.filter((c) => c === "");
              if (emptyCode.length === 0) {
                const code = values.code.join("");
                setLoading(true);
                await verifyAccount(
                  code,
                  values.method,
                  user.user.phone,
                  `${user.user.fname} ${user.user.lname}`
                );
                enqueueSnackbar("Your code has been verified succesfully!", {
                  variant: "success",
                });
                setValues((prev) => ({ ...prev, isVerified: true }));
              } else {
                throw new Error(
                  "Please enter a valid verification code to proceed."
                );
              }
            }
          } else {
            setLoading(true);
            await sendVerifyCode(values.phone, values.country, values.method);
            enqueueSnackbar("Verification code has been sent!", {
              variant: "success",
            });
            setValues((prev) => ({ ...prev, isSent: true }));
          }
        } else {
          throw new Error("Please select a verification method to proceed.");
        }
        setLoading(false);
        return;
      }
      const isEmpty =
        Object.keys(values).filter((key) => !values[key]).length > 0;
      if (selected.isOptional || !isEmpty) {
        if (
          selected.type === "password" ||
          values.password !== values.npassword
        ) {
          throw new Error("Please confirm your password to proceed.");
        }
        setLoading(true);
        if (selected.value === "account") {
          await updateSettings({ accountStatus: 2 }, selected.value);
          enqueueSnackbar("Your account has been deactivated!", {
            variant: "success",
          });
          logout();
        } else if (selected.value === "device") {
          await logoutDevice(values);
          enqueueSnackbar("Device has been logged out!", {
            variant: "success",
          });
          setDevices(await getDevices());
        } else {
          await updateSettings(values, selected.value);
          enqueueSnackbar("Settings updated successfully!", {
            variant: "success",
          });
        }
        handleClose();
      } else {
        throw new Error("Please fill all required fields to proceed.");
      }
    } catch (err) {
      enqueueSnackbar(err.message || err, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    const get = async () => {
      try {
        setDevices(await getDevices());
      } catch (err) {
        enqueueSnackbar(err.message || err, {
          variant: "error",
        });
      }
    };
    get();
    return () => {
      setDevices([]);
    };
  }, [enqueueSnackbar, getDevices]);
  return (
    <>
      <Grid container spacing={{ md: 3, xs: 0 }}>
        <Grid item xs={12} md={8}>
          <Stack spacing={4}>
            <Card
              sx={{
                py: 1,
                px: 3,
                boxShadow: {
                  md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  xs: "none",
                },
                borderRadius: 1,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Stack alignItems="start" spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "rgba(64, 60, 60, 1)",
                    }}
                  >
                    Password
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 14,
                      fontWeight: 300,
                      color: "rgba(98, 98, 98, 1)",
                    }}
                  >
                    {(user &&
                      user.user &&
                      user.user.passwordUpdated &&
                      `Last updated: ${fToNow(user.user.passwordUpdated)}`) ||
                      `Not Updated Yet`}
                  </Typography>
                </Stack>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setSelected({
                      title: "Update your password",
                      subtitle: "Enter your current and new password below",
                      values: ["password", "cpassword", "npassword"],
                      value: "password",
                      isOptional: false,
                    });
                    setValues({ password: "", cpassword: "", npassword: "" });
                    setIsEdit(true);
                  }}
                  sx={{
                    textDecoration: "underline",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                >
                  Update
                </Button>
              </Stack>
            </Card>
            <Stack spacing={{ md: 3, xs: 1 }}>
              <Typography
                variant="subtitl2"
                sx={{ fontSize: { md: 20, xs: 16 }, fontWeight: 500 }}
              >
                1 step authentication
              </Typography>
              <Card
                sx={{
                  py: 1,
                  px: 3,
                  boxShadow: {
                    md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                    xs: "none",
                  },
                  borderRadius: 1,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack alignItems="start" spacing={0.5}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      PIN
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 300,
                        color: "rgba(98, 98, 98, 1)",
                      }}
                    >
                      {(user &&
                        user.user &&
                        user.user.pinUpdated &&
                        `Last Updated: ${fToNow(user.user.pinUpdated)}`) ||
                        `No PIN set`}
                    </Typography>
                  </Stack>
                  <Button
                    variant="text"
                    startIcon={<CreateOutlinedIcon fontSize="small" />}
                    size="small"
                    onClick={() => {
                      setValues({
                        country: user.user.country,
                        phone: user.user.phone,
                        method: "",
                        isSent: false,
                        isVerified: false,
                        code: ["", "", "", "", "", ""],
                        pin: ["", "", "", ""],
                      });
                      setIsPin(true);
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    Edit
                  </Button>
                </Stack>
              </Card>
            </Stack>
            <Stack spacing={{ md: 3, xs: 1 }}>
              <Typography
                variant="subtitl2"
                sx={{ fontSize: { md: 20, xs: 16 }, fontWeight: 500 }}
              >
                Device history
              </Typography>
              <Card
                sx={{
                  py: 1,
                  px: 3,
                  boxShadow: {
                    md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                    xs: "none",
                  },
                  borderRadius: 1,
                }}
              >
                <Stack
                  spacing={2}
                  divider={<Divider flexItem orientation="horizontal" />}
                >
                  {devices.map((device, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Stack alignItems="start" spacing={0.5}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Icon
                            icon={DEVICE_TYPES[parseInt(device.type, 10)].icon}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            {device.os}&nbsp;&middot;&nbsp;{device.name}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: { md: 14, xs: 12 },
                            fontWeight: 300,
                            color: "rgba(98, 98, 98, 1)",
                          }}
                        >
                          {device.location || "No Location recorded"}
                          &nbsp;&middot;&nbsp;
                          {fDateTime(device.lastLogin)}
                        </Typography>
                      </Stack>
                      {(parseInt(device.status, 10) === 1 && (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            setSelected({
                              title: "Logout Device",
                              subtitle:
                                "Please confirm you want to logout this device.",
                              values: [],
                              value: "device",
                              isOptional: true,
                            });
                            setValues(device);
                            setIsEdit(true);
                          }}
                          sx={{
                            textDecoration: "underline",
                            fontWeight: 300,
                            fontSize: { md: 14, xs: 12 },
                          }}
                        >
                          Logout device
                        </Button>
                      )) || (
                        <Label
                          variant="outlined"
                          color={
                            DEVICE_STATUS[parseInt(device.status, 10)].color
                          }
                        >
                          {DEVICE_STATUS[parseInt(device.status, 10)].label}
                        </Label>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Card>
            </Stack>
            <Stack spacing={{ md: 3, xs: 1 }}>
              <Typography
                variant="subtitl2"
                sx={{ fontSize: { md: 20, xs: 16 }, fontWeight: 500 }}
              >
                Deactivate Account
              </Typography>
              <Card
                sx={{
                  py: 1,
                  px: 3,
                  boxShadow: {
                    md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                    xs: "none",
                  },
                  borderRadius: 1,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack alignItems="start" spacing={0.5}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      Deactivate account
                    </Typography>
                  </Stack>
                  <Button
                    variant="text"
                    size="small"
                    color="error"
                    onClick={() => {
                      setSelected({
                        title: "Deactivate Account",
                        subtitle:
                          "Please confirm you want to deactivate your account.\nNote that once your account has been deactivated, all your listings will be disabled and all pending payments will be processed to your account.",
                        values: [],
                        value: "account",
                        isOptional: true,
                      });
                      setValues({});
                      setIsEdit(true);
                    }}
                    sx={{
                      textDecoration: { md: "underline", xs: "none" },
                      fontWeight: { md: 300, xs: 500 },
                      fontSize: 14,
                    }}
                  >
                    Deactivate
                  </Button>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {isEdit && (
        <Edit
          open={isEdit}
          title={selected.title}
          subtitle={selected.subtitle}
          type={selected.value}
          handleClose={handleClose}
          values={values}
          setValue={setValues}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      )}
      {isPin && (
        <PINVerify
          open={isPin}
          setValue={setValues}
          values={values}
          isLoading={isLoading}
          handleSave={handleSave}
          handleClose={handleClose}
          inputRefs={inputRefs}
          handleKeyPress={handleKeyPress}
        />
      )}
    </>
  );
}
