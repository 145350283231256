// components
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "General",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "Reservations",
    items: [
      {
        title: "All Bookings",
        path: PATH_DASHBOARD.bookings,
        icon: <MenuBookOutlinedIcon />,
      },
      {
        title: "Add Booking",
        path: PATH_DASHBOARD.addBookings,
        icon: <BookmarkAddOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "Listings",
    items: [
      {
        title: "All Listings",
        path: PATH_DASHBOARD.listings,
        icon: <MenuBookOutlinedIcon />,
      },
      {
        title: "Add Listing",
        path: PATH_DASHBOARD.addListings,
        icon: <SummarizeOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "Administrative",
    items: [
      {
        title: "Staff",
        path: PATH_DASHBOARD.staff,
        icon: <SupervisorAccountOutlinedIcon />,
      },
      {
        title: "Staff Roles",
        path: PATH_DASHBOARD.roles,
        icon: <AdminPanelSettingsOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "",
    items: [
      {
        title: "Earning",
        path: PATH_DASHBOARD.earnings,
        icon: <PaymentsOutlinedIcon />,
      },
      {
        title: "Wallet",
        path: PATH_DASHBOARD.wallet,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        title: "Messaging",
        path: PATH_DASHBOARD.messages,
        icon: <ChatOutlinedIcon />,
      },
      {
        title: "Reviews",
        path: PATH_DASHBOARD.reviews,
        icon: <VisibilityOutlinedIcon />,
      },

      {
        title: "Notifications",
        path: PATH_DASHBOARD.general.notification,
        icon: <NotificationsActiveOutlinedIcon />,
      },
      {
        title: "Insights",
        path: PATH_DASHBOARD.insights,
        icon: <InsightsOutlinedIcon />,
      },
    ],
  },
];
export const eventSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
      {
        title: "Tickets",
        path: PATH_DASHBOARD.tickets,
        icon: <MenuBookOutlinedIcon />,
        children: [
          { title: "Guest list", path: PATH_DASHBOARD.tickets },
          { title: "Ticket types", path: PATH_DASHBOARD.ticketTypes },
          { title: "Checked In", path: PATH_DASHBOARD.checkedIn },
          { title: "Add ticket", path: PATH_DASHBOARD.addTicket },
        ],
      },
      {
        title: "Listing",
        path: PATH_DASHBOARD.listings,
        icon: <ReceiptLongOutlinedIcon />,
      },
      {
        title: "Earning",
        path: PATH_DASHBOARD.earnings,
        icon: <PaymentsOutlinedIcon />,
      },
      {
        title: "Wallet",
        path: PATH_DASHBOARD.wallet,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        title: "Messaging",
        path: PATH_DASHBOARD.messages,
        icon: <ChatOutlinedIcon />,
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: "Notifications",
        path: PATH_DASHBOARD.general.notification,
        icon: <NotificationsActiveOutlinedIcon />,
      },
      {
        title: "Insights",
        path: PATH_DASHBOARD.insights,
        icon: <InsightsOutlinedIcon />,
      },
    ],
  },
];
export const attractionSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
      {
        title: "Tickets",
        path: PATH_DASHBOARD.tickets,
        icon: <MenuBookOutlinedIcon />,
        children: [
          { title: "Guest list", path: PATH_DASHBOARD.tickets },
          { title: "Ticket types", path: PATH_DASHBOARD.ticketTypes },
          { title: "Checked In", path: PATH_DASHBOARD.checkedIn },
          { title: "Add ticket", path: PATH_DASHBOARD.addTicket },
        ],
      },
      {
        title: "Listing",
        path: PATH_DASHBOARD.listings,
        icon: <ReceiptLongOutlinedIcon />,
      },
      {
        title: "Earning",
        path: PATH_DASHBOARD.earnings,
        icon: <PaymentsOutlinedIcon />,
      },
      {
        title: "Wallet",
        path: PATH_DASHBOARD.wallet,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        title: "Messaging",
        path: PATH_DASHBOARD.messages,
        icon: <ChatOutlinedIcon />,
      },
      {
        title: "Reviews",
        path: PATH_DASHBOARD.reviews,
        icon: <VisibilityOutlinedIcon />,
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: "Notifications",
        path: PATH_DASHBOARD.general.notification,
        icon: <NotificationsActiveOutlinedIcon />,
      },
      {
        title: "Insights",
        path: PATH_DASHBOARD.insights,
        icon: <InsightsOutlinedIcon />,
      },
    ],
  },
];
export const propertySidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
      {
        title: "Listing",
        path: PATH_DASHBOARD.listings,
        icon: <ReceiptLongOutlinedIcon />,
      },
      {
        title: "Insights",
        path: PATH_DASHBOARD.insights,
        icon: <InsightsOutlinedIcon />,
      },
      {
        title: "Subscriptions",
        path: PATH_DASHBOARD.general.subscriptions,
        icon: <PaymentsOutlinedIcon />,
      },
      {
        title: "Messaging",
        path: PATH_DASHBOARD.messages,
        icon: <ChatOutlinedIcon />,
      },
      {
        title: "Wallet",
        path: PATH_DASHBOARD.wallet,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        title: "Leads",
        path: PATH_DASHBOARD.leads,
        icon: <VisibilityOutlinedIcon />,
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsOutlinedIcon />,
      },
    ],
  },
];
