import * as Yup from "yup";
import { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Alert,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  FormControlLabel,
  Divider,
  OutlinedInput,
  Radio,
  IconButton,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import { COUNTRIES } from "../../../utils/apis";
import GoogleButton from "./GoogleButton";
import Logo from "src/components/Logo";

const LENGTH = 6;
const clamp = (min, max, val) => Math.max(min, Math.min(val, max));
function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(
      0,
      object.target.maxLength
    ));
  }
  return false;
}
const OPTIONS = [
  {
    method: "sms",
    name: "Text message (SMS)",
    message: "We will text you a code",
    icon: "material-symbols:chat-outline",
  },
  {
    method: "whatsapp",
    name: "WhatsApp",
    message: "We will send a code over wifi",
    icon: "ic:outline-whatsapp",
  },
  {
    method: "call",
    name: "Phone call",
    message: "We will call you with a code",
    icon: "material-symbols:call-outline",
  },
];
export default function LoginForm({ steps, setSteps }) {
  const navigate = useNavigate();
  const { login, user, checkPhone, sendVerifyCode, verifyAccount, signup } =
    useAuth();
  const [isLogin, setIsLogin] = useState(false);
  const [isUseEmail, setIsEmail] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);

  const inputRefs = useRef([]);
  const [data] = useState([...Array(LENGTH).keys()]);

  const handleKeyPress = (index) => () => {
    let nextIndex = clamp(0, data.length - 1, index + 1);
    const current = inputRefs.current[index];
    setTimeout(() => {
      if (current.value === "") {
        // go backwards
        nextIndex = index - 1 < 0 ? 0 : index - 1;
      }
      current.blur();
      inputRefs.current[nextIndex].focus();
    }, 100);
  };

  const codesValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  };
  const codesEmailValues = {
    codeEmail1: "",
    codeEmail2: "",
    codeEmail3: "",
    codeEmail4: "",
    codeEmail5: "",
    codeEmail6: "",
  };

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required(
      "Please provide your valid phone number to proceed."
    ),
  });
  const resendPhoneCode = async () => {
    try {
      setIsResending(true);
      await sendVerifyCode(values.phone, values.country, values.phoneMethod);
      enqueueSnackbar("Verification code has been sent!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setIsResending(false);
    setSteps(1);
  };
  const resendEmailCode = async () => {
    try {
      setIsResending(true);
      await sendVerifyCode(
        user.user.email,
        `${user.user.fname} ${user.user.lname}`,
        "email"
      );
      enqueueSnackbar("Verification code has been sent!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setIsResending(false);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      country: "NG",
      phone: (user && user.user && user.user.phone) || "",
      phoneMethod: "sms",
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      code5: "",
      code6: "",
      firstName: "",
      lastName: "",
      email: "",
      codeEmail1: "",
      codeEmail2: "",
      codeEmail3: "",
      codeEmail4: "",
      codeEmail5: "",
      codeEmail6: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        if (steps === 0) {
          const result = await checkPhone(values.phone, values.country);
          setSubmitting(false);
          if (isUseEmail) {
            await login(values.email, values.password);
            enqueueSnackbar("Logged in successfully!", { variant: "success" });
            setSubmitting(false);
          } else if (result === "no_account") {
            // sign up, proceed to step 2
            setIsLogin(false);
            setSteps(1);
          } else if (values.password) {
            // login to account
            await login(values.phone, values.password);
            enqueueSnackbar("Logged in successfully!", { variant: "success" });
            setSubmitting(false);
          } else {
            // login, show password
            setIsLogin(true);
          }
        } else if (steps === 1) {
          if (values.phone) {
            // check code for validation
            const code =
              String(values.code1) +
              String(values.code2) +
              String(values.code3) +
              String(values.code4) +
              String(values.code5) +
              String(values.code6);
            await verifyAccount(
              code,
              values.phoneMethod,
              values.phone,
              values.country
            );
            setSubmitting(false);
            setSteps(3);
          } else {
            setSteps(0);
            enqueueSnackbar("Please enter a valid phone number to proceed.", {
              variant: "error",
            });
          }
        } else if (steps === 3) {
          if (
            values.firstName &&
            values.lastName &&
            values.email &&
            values.password
          ) {
            await signup(values);
            setSubmitting(false);
            enqueueSnackbar("Your account has been created successfully!", {
              variant: "success",
            });
          } else {
            setErrors({
              afterSubmit: "Please fill all required fields to proceed.",
            });
          }
        } else if (steps === 4) {
          if (user.user.email) {
            // check code for validation
            const code =
              String(values.codeEmail1) +
              String(values.codeEmail2) +
              String(values.codeEmail3) +
              String(values.codeEmail4) +
              String(values.codeEmail5) +
              String(values.codeEmail6);
            await verifyAccount(
              code,
              "email",
              user.user.email,
              `${user.user.fname} ${user.user.lname}`
            );
            setSubmitting(false);
            enqueueSnackbar("Your account has been verified successfully!", {
              variant: "success",
            });
            navigate(PATH_DASHBOARD.root);
          } else {
            setSteps(0);
            enqueueSnackbar("Please enter a valid phone number to proceed.", {
              variant: "error",
            });
          }
        }
      } catch (error) {
        setSubmitting(false);
        setErrors({ afterSubmit: error.message });
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  const handleGoogleResponse = async (response) => {
    try {
      if (response.email) {
        setIsGoogleSubmitting(true);
        await login(response.email, response.id, "google");
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setIsGoogleSubmitting(false);
  };

  return (
    <Box
      sx={{
        bgcolor: { md: "none", xs: "rgba(255, 255, 255, 1)" },
        borderTopLeftRadius: { md: 0, xs: "20px" },
        borderTopRightRadius: { md: 0, xs: "20px" },
        p: { md: 0, xs: 3 },
        alignItems: "stretch",
        position: "relative",
        width: 1,
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: 80,
          height: 80,
          borderRadius: "50%",
          boxShadow: "-1px 1px 12px 0px rgba(0, 0, 0, 0.04)",
          top: -40,
          left: "calc(50% - 40px)",
          right: 0,
          bgcolor: "rgba(255, 255, 255, 1)",
          display: { md: "none", xs: steps === 0 ? "flex" : "none" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo sx={{ height: 45 }} />
      </Box>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: 1,
              height: 1,
              pl: {
                md: steps === 1 || steps === 3 ? "2rem" : "0px",
                xs: 0,
              },
            }}
          >
            <Stack
              spacing={3}
              sx={{
                width: 1,
                height: {
                  md: steps === 0 ? "auto" : 1,
                  xs: steps === 0 ? "auto" : 1,
                },
                pt: { md: 0, xs: steps === 0 ? 3 : 0 },
              }}
            >
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              {steps === 0 && !isUseEmail && (
                <FormControlLabel
                  label="Country code"
                  labelPlacement="top"
                  sx={{
                    "& span": {
                      fontSize: 14,
                      fontWeight: 500,
                      color: "rgba(64, 60, 60, 1)",
                      pb: 2,
                    },
                    alignItems: "start",
                    mx: 0,
                  }}
                  control={
                    <Autocomplete
                      fullWidth
                      options={COUNTRIES}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "rgba(248, 250, 252, 1)",
                        },
                        "& fieldset": {
                          border: "1px solid rgba(203, 213, 225, 1)",
                        },
                      }}
                      getOptionLabel={(option) =>
                        option.name
                          ? `${option.name || ""} (${option.dial_code || ""})`
                          : ""
                      }
                      {...getFieldProps("country")}
                      value={
                        COUNTRIES.filter(
                          (country) => country.code === values.country
                        )[0]
                      }
                      name="country"
                      onChange={(event, newValue) => {
                        if (newValue.name) {
                          setFieldValue("country", newValue.code);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.code === value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select your country"
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                  }
                />
              )}
              {steps === 0 && !isUseEmail && (
                <FormControlLabel
                  label="Phone Number"
                  labelPlacement="top"
                  sx={{
                    "& span": {
                      fontSize: 13,
                      fontWeight: 300,
                      color: "rgba(9, 9, 20, 1)",
                      pt: 0.5,
                      pl: 2,
                    },
                    alignItems: "start",
                    bgcolor: "rgba(248, 250, 252, 1)",
                    border: "1px solid rgba(203, 213, 225, 1)",
                    borderRadius: 1,
                  }}
                  control={
                    <TextField
                      fullWidth
                      autoComplete="tel"
                      type="tel"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {
                              COUNTRIES.filter(
                                (country) => country.code === values.country
                              )[0].dial_code
                            }
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "rgba(248, 250, 252, 1)",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                        "& .MuiInputAdornment-root p": {
                          color: "rgba(9, 9, 20, 1)",
                          fontWeight: 500,
                        },
                        "& input": {
                          py: 0.5,
                        },
                      }}
                    />
                  }
                />
              )}
              {steps === 0 && isUseEmail && (
                <FormControlLabel
                  label="Email address"
                  labelPlacement="top"
                  sx={{
                    "& span": {
                      fontSize: 13,
                      fontWeight: 300,
                      color: "rgba(9, 9, 20, 1)",
                      pt: 0.5,
                    },
                    alignItems: "start",
                    mx: 0,
                    width: 1,
                  }}
                  control={
                    <TextField
                      fullWidth
                      type="email"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "rgba(248, 250, 252, 1)",
                        },
                        "& fieldset": {
                          border: "1px solid rgba(203, 213, 225, 1)",
                        },
                      }}
                    />
                  }
                />
              )}
              {steps === 0 && isLogin && (
                <FormControlLabel
                  label="Password"
                  labelPlacement="top"
                  sx={{
                    "& span": {
                      fontSize: 13,
                      fontWeight: 300,
                      color: "rgba(9, 9, 20, 1)",
                      pt: 0.5,
                    },
                    alignItems: "start",
                    mx: 0,
                    width: 1,
                  }}
                  control={
                    <TextField
                      fullWidth
                      autoComplete="current-password"
                      type={showPassword ? "text" : "password"}
                      {...getFieldProps("password")}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              <Icon
                                icon={showPassword ? eyeFill : eyeOffFill}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "rgba(248, 250, 252, 1)",
                        },
                        "& fieldset": {
                          border: "1px solid rgba(203, 213, 225, 1)",
                        },
                      }}
                    />
                  }
                />
              )}
              {steps === 0 && !isLogin && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "start",
                    color: { md: "text.secondary", xs: "rgba(78, 78, 78, 1)" },
                    fontSize: { md: "0.875rem", xs: 12 },
                  }}
                >
                  We'll call or text you to confirm your number. Standard
                  message and data rates apply.
                  <Link
                    variant="subtitle2"
                    underline="none"
                    href={PATH_AUTH.signup}
                    sx={{
                      color: "rgba(82, 82, 91, 1)",
                      fontWeight: 600,
                      fontSize: { md: "0.875rem", xs: 12 },
                    }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              )}
              {steps === 1 && (
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent={{ md: "space-between", xs: "start" }}
                  sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                    width: 1,
                    pt: { md: 10, xs: 2 },
                  }}
                >
                  <Stack sx={{ width: 1 }} spacing={2}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: {
                          md: "text.secondary",
                          xs: "rgba(78, 78, 78, 1)",
                        },
                        fontSize: { md: "1.1rem", xs: 14 },
                        fontWeight: 400,
                        textAlign: "start",
                      }}
                    >
                      Enter the code we sent over SMS to{" "}
                      {
                        COUNTRIES.filter(
                          (country) => country.code === values.country
                        )[0].dial_code
                      }
                      &nbsp;{values.phone}:
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="start"
                      alignItems="center"
                      sx={{
                        bgcolor: "rgba(248, 250, 252, 1)",
                        border: "1px solid rgba(203, 213, 225, 1)",
                        borderRadius: 1,
                        p: 0.5,
                        width: "fit-content",
                      }}
                    >
                      {Object.keys(codesValues).map((item, index) => (
                        <OutlinedInput
                          key={item}
                          {...getFieldProps(item)}
                          type="number"
                          placeholder="-"
                          onInput={maxLength}
                          error={Boolean(touched[item] && errors[item])}
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                          autoFocus={Boolean(index === 0)}
                          inputProps={{
                            onChange: handleKeyPress(index),
                            onKeyPress: handleKeyPress(index),
                            maxLength: 1,
                            sx: {
                              p: 0,
                              textAlign: "center",
                              width: { xs: 36, sm: 56 },
                              height: { xs: 36, sm: 56 },
                              color: "rgba(0, 0, 0, 1)",
                            },
                          }}
                          sx={{ "& fieldset": { border: "none" } }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ md: "row", xs: "column" }}
                    alignItems={{ md: "center", xs: "start" }}
                    justifyContent="space-between"
                    spacing={{ md: 4, xs: 2 }}
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.25)",
                      py: 2,
                      width: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography
                        variant="body2"
                        sx={{
                          display: { md: "none", xs: "block" },
                          fontSize: 14,
                          color: "rgba(64, 60, 60, 1)",
                          fontWeight: 400,
                        }}
                      >
                        Did not get a code?
                      </Typography>
                      <Link
                        underline="always"
                        sx={{
                          color: {
                            md: "text.primary",
                            xs: "rgba(64, 60, 60, 1)",
                          },
                          textDecorationColor: {
                            md: "#090914",
                            xs: "rgba(64, 60, 60, 1)",
                          },
                          cursor: "pointer",
                        }}
                        variant="subtitle2"
                        onClick={() => setSteps(2)}
                      >
                        More Options
                      </Link>
                    </Stack>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={
                        !(
                          values.code1 &&
                          values.code2 &&
                          values.code3 &&
                          values.code4 &&
                          values.code5 &&
                          values.code6
                        )
                      }
                      sx={{
                        width: { md: "fit-content", xs: 1 },
                        boxShadow: "none",
                        fontWeight: 400,
                        fontSize: 15,
                        px: 10,
                        color: "rgba(64, 60, 60, 1)",
                        borderRadius: { md: 1.5, xs: 50 },
                        py: { md: 3.5, xs: "15px" },
                        bgcolor: {
                          md: "rgba(248, 250, 252, 1)",
                          xs: "rgba(65, 177, 26, 1)",
                        },
                        border: {
                          md: "1px solid rgba(203, 213, 225, 1)",
                          xs: "none",
                        },
                        "&:hover": {
                          bgcolor: {
                            md: "rgba(248, 250, 252, 0.6)",
                            xs: "rgba(65, 177, 26, 1)",
                          },
                          border: {
                            md: "1px solid rgba(203, 213, 225, 0.6)",
                            xs: "none",
                          },
                        },
                      }}
                    >
                      Continue
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
              {steps === 2 && (
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                    width: 1,
                    pt: { md: 10, xs: 2 },
                  }}
                >
                  <Stack sx={{ width: 1 }} spacing={1}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: {
                          md: "text.secondary",
                          xs: "rgba(78, 78, 78, 1)",
                        },
                        fontSize: { md: "1.1rem", xs: 16 },
                        fontWeight: 400,
                        textAlign: "start",
                      }}
                    >
                      Choose another way to get a verification code at{" "}
                      {
                        COUNTRIES.filter(
                          (country) => country.code === values.country
                        )[0].dial_code
                      }
                      &nbsp;{values.phone}:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "primary.main",
                        fontSize: { md: 14, xs: 12 },
                        fontWeight: 300,
                        textAlign: "start",
                      }}
                    >
                      Make sure your notification are turned on.
                    </Typography>
                    <Stack
                      spacing={{ md: 4, xs: 2 }}
                      justifyContent="start"
                      alignItems="start"
                      sx={{ py: 4 }}
                    >
                      {OPTIONS.map((item, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            width: 1,
                            boxShadow: {
                              md: "none",
                              xs: "-1px 1px 12px 0px rgba(0, 0, 0, 0.05)",
                            },
                            border: {
                              md: "none",
                              xs: "1px solid rgba(0, 0, 0, 0.2)",
                            },
                            borderRadius: { md: 0, xs: "10px" },
                            py: 2,
                            pl: 2,
                            pr: 1,
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="start"
                          >
                            <Icon
                              icon={item.icon}
                              width={22}
                              height={22}
                              style={{ color: "rgba(64, 60, 60, 1)" }}
                            />
                            <Stack
                              direction={{ md: "column", xs: "column-reverse" }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: { md: "1rem", xs: 14 },
                                  fontWeight: 500,
                                  color: "rgba(64, 60, 60, 1)",
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: 13,
                                  fontWeight: 300,
                                  color: "rgba(64, 60, 60, 1)",
                                }}
                              >
                                {item.message}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Radio
                            value={item.method}
                            checked={values.phoneMethod === item.method}
                            onChange={() => {
                              setFieldValue("phoneMethod", item.method);
                            }}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={4}
                    sx={{
                      borderTop: {
                        md: "1px solid rgba(0, 0, 0, 0.25)",
                        xs: "none",
                      },
                      py: 4,
                      width: 1,
                    }}
                  >
                    <LoadingButton
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={resendPhoneCode}
                      loading={isResending}
                      sx={{
                        boxShadow: {
                          md: "none",
                          xs: "-1px 1px 12px 0px rgba(0, 0, 0, 0.05)",
                        },

                        fontWeight: 500,
                        fontSize: { md: 16, xs: 14 },
                        px: 10,
                        borderRadius: { md: 1.5, xs: "50px" },
                        py: { md: 3.5, xs: "14px" },
                      }}
                    >
                      Resend code
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
              {steps === 3 && (
                <Stack
                  spacing={{ md: 3, xs: 2 }}
                  alignItems="start"
                  sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                    width: 1,
                    pt: { md: 10, xs: 2 },
                  }}
                >
                  <Stack sx={{ width: 1 }} spacing={2}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(64, 60, 60, 1)",
                        display: { md: "block", xs: "none" },
                      }}
                    >
                      Legal name
                    </Typography>
                    <Stack
                      direction={{ md: "row", xs: "column" }}
                      spacing={3}
                      alignItems={{ md: "center", xs: "start" }}
                      sx={{ width: 1 }}
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        label="First name on ID"
                        labelPlacement="top"
                        sx={{
                          width: 1,
                          "& span": {
                            fontSize: 13,
                            fontWeight: 300,
                            color: "rgba(9, 9, 20, 1)",
                            pt: 0.5,
                            pl: 2,
                          },
                          alignItems: "start",
                          bgcolor: "rgba(248, 250, 252, 1)",
                          border: "1px solid rgba(203, 213, 225, 1)",
                          borderRadius: 1,
                          flexGrow: 1,
                          mx: 0,
                        }}
                        control={
                          <TextField
                            fullWidth
                            autoComplete="username"
                            type="text"
                            {...getFieldProps("firstName")}
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            helperText={touched.firstName && errors.firstName}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                bgcolor: "rgba(248, 250, 252, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              "& input": {
                                py: 0.5,
                              },
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Last name on ID"
                        labelPlacement="top"
                        sx={{
                          width: 1,
                          "& span": {
                            fontSize: 13,
                            fontWeight: 300,
                            color: "rgba(9, 9, 20, 1)",
                            pt: 0.5,
                            pl: 2,
                          },
                          alignItems: "start",
                          bgcolor: "rgba(248, 250, 252, 1)",
                          border: "1px solid rgba(203, 213, 225, 1)",
                          borderRadius: 1,
                          flexGrow: 1,
                          mx: 0,
                        }}
                        control={
                          <TextField
                            fullWidth
                            autoComplete="username"
                            type="text"
                            {...getFieldProps("lastName")}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                bgcolor: "rgba(248, 250, 252, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              "& input": {
                                py: 0.5,
                              },
                            }}
                          />
                        }
                      />
                    </Stack>
                  </Stack>
                  <Stack sx={{ width: 1 }} spacing={2}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        display: { md: "block", xs: "none" },
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      Contact Info
                    </Typography>
                    <FormControlLabel
                      label="Email"
                      labelPlacement="top"
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 300,
                          color: "rgba(9, 9, 20, 1)",
                          pt: 0.5,
                          pl: 2,
                        },
                        alignItems: "start",
                        bgcolor: "rgba(248, 250, 252, 1)",
                        border: "1px solid rgba(203, 213, 225, 1)",
                        borderRadius: 1,
                      }}
                      control={
                        <TextField
                          fullWidth
                          autoComplete="email"
                          type="email"
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              bgcolor: "rgba(248, 250, 252, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            "& input": {
                              py: 0.5,
                            },
                          }}
                        />
                      }
                    />
                  </Stack>
                  <FormControlLabel
                    label="Password"
                    labelPlacement="top"
                    sx={{
                      "& span": {
                        fontSize: 13,
                        fontWeight: 300,
                        color: "rgba(9, 9, 20, 1)",
                        pt: 0.5,
                      },
                      alignItems: "start",
                      mx: 0,
                      width: 1,
                    }}
                    control={
                      <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        {...getFieldProps("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon
                                  icon={showPassword ? eyeFill : eyeOffFill}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            bgcolor: "rgba(248, 250, 252, 1)",
                          },
                          "& fieldset": {
                            border: "1px solid rgba(203, 213, 225, 1)",
                          },
                        }}
                      />
                    }
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "start",
                      color: "rgba(82, 82, 91, 1)",
                      fontSize: { md: "0.8rem", xs: "0.7rem" },
                    }}
                  >
                    By selecting Agree and continue, I agree to Afritas{" "}
                    <Link
                      variant="subtitle2"
                      underline="none"
                      href={PATH_PAGE.terms}
                      target="_blank"
                      sx={{
                        color: "rgba(82, 82, 91, 1)",
                        fontWeight: 500,
                        fontSize: { md: "0.8rem", xs: "0.7rem" },
                      }}
                    >
                      Terms of Service
                    </Link>
                    ,{" "}
                    <Link
                      variant="subtitle2"
                      underline="none"
                      href={PATH_PAGE.paymentTerms}
                      target="_blank"
                      sx={{
                        color: "rgba(82, 82, 91, 1)",
                        fontWeight: 500,
                        fontSize: { md: "0.8rem", xs: "0.7rem" },
                      }}
                    >
                      Payments Terms of Service
                    </Link>
                    , and acknowledge the 
                    <Link
                      variant="subtitle2"
                      underline="none"
                      target="_blank"
                      href={PATH_PAGE.privacy}
                      sx={{
                        color: "rgba(82, 82, 91, 1)",
                        fontWeight: 500,
                        fontSize: { md: "0.8rem", xs: "0.7rem" },
                      }}
                    >
                      Privacy Policy.
                    </Link>
                  </Typography>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                      mt: "4rem!important",
                      boxShadow: "none",
                      fontWeight: 500,
                      borderRadius: { md: 2, xs: "50px" },
                      py: { md: 3.5, xs: "14px" },
                      fontSize: { md: "1.1rem", xs: 14 },
                    }}
                  >
                    Agree & Continue
                  </LoadingButton>
                </Stack>
              )}
              {steps === 4 && (
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ flexGrow: 1, flexShrink: 0, width: 1, pt: 10 }}
                >
                  <Stack sx={{ width: 1 }} spacing={4}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(64, 60, 60, 1)",
                        fontSize: "1.1rem",
                        fontWeight: 500,
                        textAlign: "start",
                      }}
                    >
                      Enter your verification code
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(64, 60, 60, 1)",
                        fontSize: "1rem",
                        fontWeight: 400,
                        textAlign: "start",
                      }}
                    >
                      Enter the code we emailed to {user.user.email || ""}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="start"
                      alignItems="center"
                      sx={{
                        bgcolor: "rgba(248, 250, 252, 1)",
                        border: "1px solid rgba(203, 213, 225, 1)",
                        borderRadius: 1,
                        p: 0.5,
                        width: "fit-content",
                      }}
                    >
                      {Object.keys(codesEmailValues).map((item, index) => (
                        <OutlinedInput
                          key={item}
                          {...getFieldProps(item)}
                          type="number"
                          placeholder="-"
                          onInput={maxLength}
                          error={Boolean(touched[item] && errors[item])}
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                          autoFocus={Boolean(index === 0)}
                          inputProps={{
                            onChange: handleKeyPress(index),
                            onKeyPress: handleKeyPress(index),
                            maxLength: 1,
                            sx: {
                              p: 0,
                              textAlign: "center",
                              width: { xs: 36, sm: 56 },
                              height: { xs: 36, sm: 56 },
                              color: "rgba(0, 0, 0, 1)",
                            },
                          }}
                          sx={{ "& fieldset": { border: "none" } }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={4}
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.25)",
                      py: 2,
                      width: 1,
                    }}
                  >
                    <Link
                      underline="always"
                      sx={{
                        color: "text.primary",
                        textDecorationColor: "#090914",
                        cursor: "pointer",
                      }}
                      variant="subtitle2"
                      onClick={resendEmailCode}
                    >
                      Resend Code
                    </Link>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      sx={{
                        width: "fit-content",
                        boxShadow: "none",
                        fontWeight: 400,
                        fontSize: 15,
                        px: 10,
                        color: "rgba(64, 60, 60, 1)",
                        borderRadius: 1.5,
                        py: 3.5,
                        bgcolor: "rgba(248, 250, 252, 1)",
                        border: "1px solid rgba(203, 213, 225, 1)",
                        "&:hover": {
                          bgcolor: "rgba(248, 250, 252, 0.6)",
                          border: "1px solid rgba(203, 213, 225, 0.6)",
                        },
                      }}
                    >
                      Continue
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
            </Stack>

            {isLogin && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ my: 2 }}
              >
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to={PATH_AUTH.forgotPassword}
                >
                  Forgot password?
                </Link>
              </Stack>
            )}
            {steps === 0 && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{
                  mt: "4rem!important",
                  boxShadow: {
                    md: "none",
                    xs: "-1px 1px 12px 0px rgba(0, 0, 0, 0.05)",
                  },
                  fontWeight: { md: 500, xs: 400 },
                  borderRadius: { md: 2, xs: 50 },
                  py: { md: 3.5, xs: 1.5 },
                  fontSize: { md: "1.1rem", xs: 15 },
                }}
              >
                Continue
              </LoadingButton>
            )}
            {steps === 0 && (
              <Stack sx={{ pt: 6 }}>
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{ pb: { md: 6, xs: 1 } }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      boxShadow: {
                        md: "none",
                        xs: "-1px 1px 12px 0px rgba(0, 0, 0, 0.05)",
                      },
                      borderRadius: { md: 0, xs: "50%" },
                      bgcolor: "#fff",
                      width: { md: "auto", xs: 35 },
                      height: { md: "auto", xs: 35 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Or
                  </Typography>
                </Divider>
                <Stack
                  direction={{ md: "row", xs: "column" }}
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <GoogleButton
                    handleResponse={handleGoogleResponse}
                    type="login"
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    startIcon={<GoogleIcon />}
                    loading={isGoogleSubmitting}
                    onClick={() => {
                      window.google.accounts.id.prompt();
                      setIsGoogleSubmitting(true);
                    }}
                    sx={{
                      boxShadow: {
                        md: "none",
                        xs: "-1px 1px 12px 0px rgba(0, 0, 0, 0.05)",
                      },
                      fontWeight: 400,
                      fontSize: 15,
                      color: "rgba(64, 60, 60, 1)",
                      borderRadius: 1.5,
                      py: 3.5,
                      bgcolor: "rgba(248, 250, 252, 1)",
                      border: {
                        md: "1px solid rgba(203, 213, 225, 1)",
                        xs: "1px solid rgba(0, 0, 0, 0.2)",
                      },
                      "&:hover": {
                        bgcolor: "rgba(248, 250, 252, 0.6)",
                        border: {
                          md: "1px solid rgba(203, 213, 225, 0.6)",
                          xs: "1px solid rgba(0, 0, 0, 0.2)",
                        },
                      },
                    }}
                  >
                    Continue with Google
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    startIcon={
                      isUseEmail ? (
                        <LocalPhoneOutlinedIcon />
                      ) : (
                        <MarkEmailUnreadOutlinedIcon />
                      )
                    }
                    onClick={() => {
                      if (!isUseEmail) {
                        setFieldValue("phone", "080");
                      } else {
                        setFieldValue("email", "");
                        setFieldValue("phone", "");
                      }
                      setIsEmail(!isUseEmail);
                      setIsLogin(!isLogin);
                    }}
                    sx={{
                      boxShadow: "none",
                      fontWeight: 400,
                      fontSize: 15,
                      color: "rgba(64, 60, 60, 1)",
                      borderRadius: 1.5,
                      py: 3.5,
                      bgcolor: "rgba(248, 250, 252, 1)",
                      border: "1px solid rgba(203, 213, 225, 1)",
                      "&:hover": {
                        bgcolor: "rgba(248, 250, 252, 0.6)",
                        border: "1px solid rgba(203, 213, 225, 0.6)",
                      },
                    }}
                  >
                    Continue with {isUseEmail ? "phone number" : "email"}
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
