import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  search: "",
  page: 0,
  perPage: 10,
  leads: [],
  overview: {},
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.leads = action.payload.data;
      state.overview = action.payload.overview;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getLeads(filters, page, perpage, order, sortBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.leads, {
        get: "",
        page,
        perpage,
        order,
        sortBy,
        ...filters,
      });
      const { status, data, overview } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, overview }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your leads at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
