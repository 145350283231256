import { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useSnackbar } from "notistack";
// material
import {
  Box,
  Paper,
  Stack,
  Card,
  Button,
  Typography,
  Grid,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { MasterCardIcon, VisaIcon, VerveIcon } from "../../../assets";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------
RemoveCard.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
};

function RemoveCard({ open, onClose, onConfirm, loading }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
      <Box sx={{ position: "relative" }}>
        <DialogTitle sx={{ fontSize: 20, fontWeight: 500 }}>
          Remove Payment Method?
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />
        </IconButton>
      </Box>
      <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Please confirm you want to remove this payment method.
        </Typography>
      </Stack>
      <DialogActions sx={{ justifyContent: "center", textAlign: "center" }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={onConfirm}
        >
          Proceed
        </LoadingButton>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
PaymentMethods.propTypes = {
  card: PropTypes.object,
  handleCardRemove: PropTypes.func,
  handleCardAdd: PropTypes.func,
};
const cardMatch = {
  mastercard: <MasterCardIcon sx={{ mb: 1 }} />,
  visa: <VisaIcon sx={{ mb: 1 }} />,
  verve: <VerveIcon sx={{ mb: 1 }} />,
  "sandbox card scheme": <VisaIcon sx={{ mb: 1 }} />,
};
export default function PaymentMethods() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateSettings } = useAuth();
  const [isRemove, setIsRemove] = useState(false);
  const paymentMethods = (user && user.paymentMethods) || [];
  const [loading, setLoading] = useState(false);
  const handleCardRemove = (methodId) => {
    setIsRemove(methodId);
  };
  const handleConfirm = async () => {
    try {
      setLoading(true);
      await updateSettings({ methodId: isRemove }, "remove_method");
      enqueueSnackbar("Payment method removed succesfully!", {
        variant: "success",
      });
      setIsRemove(false);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };
  const handleCardAdd = () => {
    navigate("/settings/payment");
  };
  return (
    <Grid container spacing={{ md: 3, xs: 1 }}>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8} sx={{ pb: 10 }}>
        <Card sx={{ boxShadow: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ pr: 2 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <CardHeader
              title="Payment Methods"
              subheader="Keep track of all your payment methods."
              sx={{
                mb: 2,
                "& .MuiCardHeader-title": {
                  fontSize: { md: 24, xs: 16 },
                  fontWeight: 500,
                },
                "& .MuiCardHeader-subheader": {
                  fontSize: { md: 13, xs: 12 },
                  fontWeight: 400,
                  color: "rgba(98, 98, 98, 1)",
                },
              }}
            />
            <Button
              size="small"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleCardAdd}
              sx={{ width: "fit-content", height: "fit-content" }}
            >
              Add
            </Button>
          </Stack>
          <Stack spacing={2} sx={{ p: 2 }}>
            {paymentMethods.map((card, index) => (
              <Stack
                key={index}
                spacing={2}
                direction={{ xs: "column", md: "row" }}
              >
                {card.type && (
                  <Paper
                    sx={{
                      p: { md: 3, xs: 2 },
                      width: 1,
                      position: "relative",
                      border: (theme) =>
                        `solid 1px ${theme.palette.grey[500_32]}`,
                    }}
                  >
                    {Object.prototype.hasOwnProperty.call(
                      cardMatch,
                      card.type.toLowerCase()
                    ) && cardMatch[card.type.toLowerCase()]}
                    <Typography variant="subtitle2">
                      **** **** **** {card.last4} - {card.bank}
                    </Typography>
                    <Button
                      sx={{ top: 8, right: 8, position: "absolute", mr: 1 }}
                      onClick={() => handleCardRemove(card.methodId)}
                      size="small"
                      color="error"
                      variant="outlined"
                    >
                      Remove
                    </Button>
                    <Typography
                      sx={{
                        bottom: 8,
                        right: 8,
                        position: "absolute",
                        mr: 1,
                        color: "text.secondary",
                      }}
                      variant="overline"
                    >
                      Expires on: {card.expiration}
                    </Typography>
                  </Paper>
                )}
              </Stack>
            ))}
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={0} md={2} />
      {isRemove && (
        <RemoveCard
          open={isRemove}
          onClose={() => {
            setIsRemove(false);
          }}
          onConfirm={handleConfirm}
          loading={loading}
        />
      )}
    </Grid>
  );
}
