import PropTypes from "prop-types";
//External imports
import { useEffect, useRef } from "react";
import { GOOGLE } from "../../../utils/apis";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleButton = ({ handleResponse, type }) => {
  const googleButton = useRef(null);

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    loadScript(src)
      .then(() => {
        function handleCredentialResponse(e) {
          try {
            const a = e.credential
              .split(".")[1]
              .replace(/-/g, "+")
              .replace(/_/g, "/");
            const s = decodeURIComponent(
              atob(a)
                .split("")
                .map(function (e) {
                  return "%" + ("00" + e.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
            );
            const res = JSON.parse(s);
            res.id = e.credential;
            handleResponse(res);
          } catch (err) {
            console.log(err);
          }
          handleResponse({});
        }
        window.google.accounts.id.initialize({
          client_id: GOOGLE.clientId,
          callback: handleCredentialResponse,
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) scriptTag.remove();
    };
  }, [handleResponse, type]);
  return <div ref={googleButton}></div>;
};
GoogleButton.propTypes = {
  handleResponse: PropTypes.func,
  type: PropTypes.string,
};

export default GoogleButton;
