import { PATH_DASHBOARD } from "../routes/paths";
import useAuth from "../hooks/useAuth";
import { MasterCardIcon, VisaIcon } from "../assets";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_API = "https://api.useafritas.com/app/";
const ROOTS_ASSETS = "https://assets.useafritas.com/";

export const API_PATH = {
  root: ROOTS_API,
  dashboard: path(ROOTS_API, "dashboard"),
  bookings: path(ROOTS_API, "bookings"),
  listings: path(ROOTS_API, "listings"),
  leads: path(ROOTS_API, "leads"),
  properties: path(ROOTS_API, "properties"),
  automobiles: path(ROOTS_API, "automobiles"),
  boats: path(ROOTS_API, "boats"),
  events: path(ROOTS_API, "events"),
  login: path(ROOTS_API, "login"),
  verify: path(ROOTS_API, "verify"),
  forgot: path(ROOTS_API, "forgot-password"),
  profile: path(ROOTS_API, "profile"),
  wallet: path(ROOTS_API, "wallet"),
  settings: path(ROOTS_API, "settings"),
  signup: path(ROOTS_API, "signup"),
  misc: path(ROOTS_API, "misc"),
  log: path(ROOTS_API, "log"),
  earnings: path(ROOTS_API, "earnings"),
  reviews: path(ROOTS_API, "reviews"),
  payment: path(ROOTS_API, "payment"),
  support: path(ROOTS_API, "support"),
  insights: path(ROOTS_API, "insights"),
  messages: path(ROOTS_API, "messages"),
  staff: path(ROOTS_API, "staff"),
  role: path(ROOTS_API, "roles"),
};
export const GENDERS = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
export const ASSETS_PATH = {
  listings: path(ROOTS_ASSETS, "listings/"),
  events: path(ROOTS_ASSETS, "events/"),
  users: path(ROOTS_ASSETS, "users/"),
  customers: path(ROOTS_ASSETS, "customers/"),
  resources: path(ROOTS_ASSETS, "resources/"),
  properties: path(ROOTS_ASSETS, "properties/"),
  boats: path(ROOTS_ASSETS, "boats/"),
  vehicles: path(ROOTS_ASSETS, "automobiles/"),
  attachments: path(ROOTS_ASSETS, "attachments/"),
};
export const PAGE_TITLES = {
  [PATH_DASHBOARD.general.root]: "Dashboard",
  [PATH_DASHBOARD.general.settings]: "Settings",
  [PATH_DASHBOARD.bookings]: "Bookings",
  [PATH_DASHBOARD.addBookings]: "Booking",
  [PATH_DASHBOARD.listings]: "Listing",
  [PATH_DASHBOARD.earnings]: "Earnings",
  [PATH_DASHBOARD.reviews]: "Reviews",
  [PATH_DASHBOARD.insights]: "Insights",
  [PATH_DASHBOARD.messages]: "Messages",
  [PATH_DASHBOARD.tickets]: "Tickets",
  [PATH_DASHBOARD.addTicket]: "Ticket",
  [PATH_DASHBOARD.ticketTypes]: "Ticket",
  [PATH_DASHBOARD.checkedIn]: "Checked In",
  [PATH_DASHBOARD.leads]: "Leads",
  [PATH_DASHBOARD.wallet]: "Wallet",
  [PATH_DASHBOARD.staff]: "Staff",
  [PATH_DASHBOARD.roles]: "Staff Roles",
};
export const IMAGE_LINKS = [
  ASSETS_PATH.listings,
  ASSETS_PATH.events,
  ASSETS_PATH.vehicles,
  ASSETS_PATH.listings,
  ASSETS_PATH.boats,
  ASSETS_PATH.properties,
  ASSETS_PATH.events,
];

export const STATES = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
export const BUSINESS_SWITCHES = [0, 3, 1, 6];
export const MONTHS = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];
export const COUNTRIES = [
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },

  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];
export const LGAS = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    state: "Federal Capital Territory",
    lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];
export const DEVICE_STATUS = [
  { label: "Logged out", color: "error" },
  {
    label: "Logged in",
    color: "success",
  },
];
export const DEVICE_TYPES = [
  { label: "Web", icon: "streamline:web", color: "error" },
  {
    label: "Windows",
    icon: "mingcute:windows-fill",
    color: "success",
  },
  {
    label: "macOs",
    icon: "wpf:macos",
    color: "success",
  },
  {
    label: "Android",
    icon: "ic:round-phone-android",
    color: "success",
  },
  {
    label: "iOs",
    icon: "material-symbols:phone-iphone-outline",
    color: "success",
  },
];
export const PAYMENT_METHODS = ["Cash", "Card / Transfer", "Complimentary"];
export const PAYMENT_METHODS_LIST = [
  { label: "mastercard", value: "mastercard", icon: <MasterCardIcon /> },
  { label: "visa", value: "visa", icon: <VisaIcon /> },
  { label: "Cash", value: "card", icon: "" },
  { label: "Bank Transfer", value: "transfer", icon: "" },
];
export const LISTING_STATUS = [
  { label: "Listing in progress", color: "warning", value: "0" },
  {
    label: "Completed",
    color: "success",
    value: "1",
  },
  {
    label: "Verification required",
    color: "info",
    value: "2",
  },
];
export const STAFF_STATUS = [
  { label: "Suspended", color: "warning", value: "0" },
  {
    label: "Active",
    color: "success",
    value: "1",
  },
];
export const PROPERTY_STATUS = [
  { label: "Listing in progress", color: "warning", value: "0" },
  {
    label: "Active",
    color: "success",
    value: "1",
  },
  {
    label: "Rented",
    color: "info",
    value: "2",
  },
  {
    label: "Expired",
    color: "error",
    value: "4",
  },
];
export const PAYMENT_STATUS = [
  { label: "Pending", color: "error", value: "0" },
  {
    label: "Successful",
    color: "success",
    value: "1",
  },
];
export const BOOKING_STATUS = [
  { label: "Upcoming", color: "info", value: "0" },
  {
    label: "Confirmed",
    color: "success",
    value: "1",
  },
  {
    label: "Cancelled",
    color: "error",
    value: "2",
  },
];
export const LEAD_STATUS = [
  { label: "New", color: "info", value: "0" },
  {
    label: "Contacted",
    color: "success",
    value: "1",
  },
  {
    label: "Inprogress",
    color: "error",
    value: "2",
  },
];
export const LEAD_PRIORITY = [
  { label: "Low", color: "success", value: "0" },
  {
    label: "High",
    color: "success",
    value: "1",
  },
  {
    label: "Medium",
    color: "success",
    value: "2",
  },
];
export const BOOKING_TYPE = [
  { label: "Afritas Booking", color: "info", value: "0" },
  {
    label: "Host booking",
    color: "success",
    value: "1",
  },
];
export const COMPLIANCE_TYPE = [
  { label: "NIN", value: "nin", title: "Enter your NIN:" },
  {
    label: "Driver's license",
    value: "driver",
    title: "Enter your Driver's license Number:",
  },
  {
    label: "International Passport",
    value: "passport",
    title: "Enter your Passport ID:",
  },
  { label: "CAC", value: "cac", title: "Enter your Business RC / BN Number:" },
];
export const COMPLIANCE_STATUS = [
  { label: "Pending", color: "warning" },
  {
    label: "Verified",
    color: "success",
  },
  { label: "Failed", color: "error" },
];
export const SUPPORT_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Replied",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
];
export const SUPPORT_REASONS = [
  "Enquiries",
  "Payment Not Made",
  "System Error",
  "Others",
];
export const LOG_TYPES = [
  { label: "System", value: "0", color: "primary" },
  { label: "Administrator", value: "1", color: "secondary" },
  { label: "Role", value: "2", color: "secondary" },
  { label: "Staff", value: "3", color: "secondary" },
  { label: "Branch", value: "4", color: "info" },
  { label: "Product Category", value: "5", color: "info" },
  { label: "Customer", value: "6", color: "info" },
  { label: "Product", value: "7", color: "info" },
  { label: "Sale/Invoice", value: "8", color: "info" },
  { label: "Returned Product", value: "9", color: "info" },
  { label: "Debtor", value: "10", color: "info" },
  { label: "Damaged Product", value: "11", color: "info" },
  { label: "Purchases", value: "12", color: "info" },
  { label: "Online Sales", value: "13", color: "info" },
  { label: "Gift Card", value: "14", color: "info" },
  { label: "Loyalty Card", value: "15", color: "info" },
  { label: "Wallet", value: "16", color: "warning" },
  { label: "Withdrawal", value: "17", color: "warning" },
  { label: "Expenses", value: "18", color: "warning" },
  { label: "Subscription", value: "19", color: "warning" },
  { label: "Message", value: "20", color: "success" },
  { label: "Import/Export", value: "21", color: "success" },
  { label: "Login", value: "22", color: "success" },
];
export const PROPERTY_TYPES = [
  { label: "Hotel", value: "hotel", icon: "material-symbols:bed-outline" },
  {
    label: "Camping house",
    value: "camping_house",
    icon: "fluent-emoji-high-contrast:camping",
  },
  {
    label: "Flat/Apartment",
    value: "apartment",
    icon: "material-symbols-light:apartment",
  },
  { label: "Boathouse", value: "boathouse", icon: "map:boat-tour" },
  { label: "Penthouse", value: "penthouse", icon: "ph:building" },
  { label: "Villa", value: "villa", icon: "material-symbols:villa-outline" },
  {
    label: "Bed & breakfast",
    value: "bedbreakfast",
    icon: "fluent-mdl2:breakfast",
  },
  {
    label: "Guest house",
    value: "guesthouse",
    icon: "hugeicons:guest-house",
  },
  { label: "Cabin", value: "cabin", icon: "material-symbols:cabin" },
  {
    label: "Bungalow",
    value: "bungalow",
    icon: "material-symbols:bungalow-outline",
  },
  { label: "House", value: "house", icon: "mingcute:house-2-line" },
  { label: "Farm house", value: "farmhouse", icon: "mdi:farm-home-outline" },
  {
    label: "Chalet",
    value: "chalet",
    icon: "material-symbols:chalet-outline",
  },
  {
    label: "Cottage",
    value: "cottage",
    icon: "material-symbols:cottage-outline",
  },
  {
    label: "Townhouse",
    value: "townhouse",
    icon: "fluent:building-townhouse-32-regular",
  },
  { label: "Resort", value: "resort", icon: "fluent-mdl2:ski-resorts" },
];
export const AUTOMOBILE_TYPES = [
  { label: "Saloon", value: "saloon", icon: "mdi:car-saloon" },
  {
    label: "Hatchback",
    value: "hatchback",
    icon: "mdi:car-hatchback",
  },
  {
    label: "Estate",
    value: "estate",
    icon: "mdi:car-estate",
  },
  { label: "4x4", value: "4x4", icon: "healthicons:4x4-outline" },
  {
    label: "MPV",
    value: "mpv",
    icon: "fluent:vehicle-car-profile-ltr-20-regular",
  },
  {
    label: "Convertible",
    value: "convertible",
    icon: "mdi:car-convertible",
  },
  {
    label: "Coupe",
    value: "coupe",
    icon: "hugeicons:car-03",
  },
  {
    label: "City-car",
    value: "citycar",
    icon: "game-icons:city-car",
  },
  {
    label: "Van or bus",
    value: "van",
    icon: "ph:van",
  },
  {
    label: "Off-road",
    value: "offroad",
    icon: "mdi:truck-off-road",
  },
];
export const BOAT_TYPES = [
  { label: "Yacht", value: "yacht", icon: "fontisto:yacht" },
  {
    label: "Sailboat",
    value: "sailboat",
    icon: "game-icons:shooner-sailboat",
  },
  {
    label: "Catamaran",
    value: "catamaran",
    icon: "arcticons:boat-crest",
  },
  {
    label: "Motorboat",
    value: "motorboat",
    icon: "emojione-monotone:motor-boat",
  },
  {
    label: "House boat",
    value: "houseboat",
    icon: "material-symbols:houseboat-outline",
  },
  {
    label: "Ship",
    value: "ship",
    icon: "mingcute:ship-line",
  },
];
export const LISTING_TYPES = [
  { label: "House", value: "house", icon: "mingcute:house-2-line" },
  {
    label: "Flat/Apartment",
    value: "apartment",
    icon: "material-symbols-light:apartment",
  },
  { label: "Boathouse", value: "boathouse", icon: "map:boat-tour" },
  { label: "Penthouse", value: "penthouse", icon: "ph:building" },
  { label: "Villa", value: "villa", icon: "material-symbols:villa-outline" },
  {
    label: "Townhouse",
    value: "townhouse",
    icon: "fluent:building-townhouse-32-regular",
  },
  {
    label: "Guest house",
    value: "guesthouse",
    icon: "hugeicons:guest-house",
  },
  { label: "Cabin", value: "cabin", icon: "material-symbols:cabin" },
  {
    label: "Bungalow",
    value: "bungalow",
    icon: "material-symbols:bungalow-outline",
  },
  { label: "Condo", value: "condo", icon: "bi:buildings" },
  { label: "Farm house", value: "farmhouse", icon: "mdi:farm-home-outline" },
  {
    label: "Chalet",
    value: "chalet",
    icon: "material-symbols:chalet-outline",
  },
  {
    label: "Cottage",
    value: "cottage",
    icon: "material-symbols:cottage-outline",
  },

  { label: "Resort", value: "resort", icon: "fluent-mdl2:ski-resorts" },
];
export const EVENTS_CATEGORIES = [
  {
    name: "Music",
    value: "music",
    subcategories: [
      "Concerts",
      "Festivals",
      "Live Bands",
      "DJ Events",
      "Open Mic Nights",
    ],
  },
  {
    name: "Sports",
    value: "sports",
    subcategories: [
      "Matches and Games (e.g., Football, Basketball)",
      "Marathons and Races",
      "Fitness Classes",
      "Yoga Sessions",
      "Extreme Sports",
    ],
  },
  {
    name: "Arts and Culture",
    value: "arts-culture",
    subcategories: [
      "Art Exhibitions",
      "Theatre and Plays",
      "Museums and Galleries",
      "Cultural Festivals",
      "Dance Performances",
    ],
  },
  {
    name: "Food and Drink",
    value: "food-drink",
    subcategories: [
      "Food Festivals",
      "Wine Tastings",
      "Cooking Classes",
      "Brewery Tours",
      "Food Truck Rallies",
    ],
  },
  {
    name: "Technology",
    value: "technology",
    subcategories: [
      "Tech Conferences",
      "Hackathons",
      "Startup Pitches",
      "Coding Bootcamps",
      "Product Launches",
    ],
  },
  {
    name: "Education",
    value: "education",
    subcategories: [
      "Workshops",
      "Seminars",
      "Webinars",
      "Lectures",
      "Training Programs",
    ],
  },
  {
    name: "Networking",
    value: "networking",
    subcategories: [
      "Business Meetups",
      "Industry Conferences",
      "Career Fairs",
      "Professional Development",
      "Alumni Events",
    ],
  },
  {
    name: "Family and Kids",
    value: "family-kids",
    subcategories: [
      "Family Festivals",
      "Kids' Workshops",
      "Storytelling Sessions",
      "Amusement Park Events",
      "Family Movie Nights",
    ],
  },
  {
    name: "Health and Wellness",
    value: "health-wellness",
    subcategories: [
      "Health Fairs",
      "Meditation Sessions",
      "Fitness Expos",
      "Wellness Retreats",
      "Nutritional Workshops",
    ],
  },
  {
    name: "Film and Media",
    value: "film-media",
    subcategories: [
      "Film Screenings",
      "Film Festivals",
      "Media Conferences",
      "Photography Exhibitions",
      "Book Readings",
    ],
  },
  {
    name: "Charity and Causes",
    value: "charity-causes",
    subcategories: [
      "Fundraisers",
      "Charity Walks/Runs",
      "Benefit Concerts",
      "Volunteer Opportunities",
      "Awareness Campaigns",
    ],
  },
  {
    name: "Hobbies and Interests",
    value: "hobbies-interests",
    subcategories: [
      "Craft Fairs",
      "Hobby Clubs (e.g., knitting, model building)",
      "Gaming Tournaments",
      "Book Clubs",
      "Gardening Workshops",
    ],
  },
  {
    name: "Fashion",
    value: "fashion",
    subcategories: [
      "Fashion Shows",
      "Designer Showcases",
      "Clothing Swap Meets",
      "Beauty Workshops",
      "Fashion Expos",
    ],
  },
  {
    name: "Travel and Outdoor",
    value: "travel-outdoor",
    subcategories: [
      "Adventure Trips",
      "Hiking Expeditions",
      "Camping Events",
      "City Tours",
      "Wildlife Safaris",
    ],
  },
  {
    name: "Religion and Spirituality",
    value: "religion-spirituality",
    subcategories: [
      "Spiritual Retreats",
      "Religious Festivals",
      "Faith-Based Conferences",
      "Prayer Meetings",
      "Meditation Workshops",
    ],
  },
  {
    name: "Government and Politics",
    value: "government-politics",
    subcategories: [
      "Political Rallies",
      "Town Hall Meetings",
      "Public Debates",
      "Policy Workshops",
      "Advocacy Events",
    ],
  },
  {
    name: "Science and Nature",
    value: "science-nature",
    subcategories: [
      "Science Fairs",
      "Nature Walks",
      "Astronomy Nights",
      "Environmental Workshops",
      "Botanical Garden Tours",
    ],
  },
  {
    name: "Shopping and Markets",
    value: "shopping-markets",
    subcategories: [
      "Flea Markets",
      "Farmers' Markets",
      "Craft Markets",
      "Pop-Up Shops",
      "Antique Fairs",
    ],
  },
  {
    name: "Holiday and Seasonal",
    value: "holiday-seasonal",
    subcategories: [
      "Christmas Markets",
      "Halloween Parties",
      "New Year's Eve Events",
      "Summer Festivals",
      "Easter Egg Hunts",
    ],
  },
  {
    name: "Virtual Events",
    value: "virtual-events",
    subcategories: [
      "Online Conferences",
      "Virtual Workshops",
      "Webinars",
      "Virtual Meetups",
      "Online Concerts",
    ],
  },
];
export const ATTRACTION_TICKETS_CATEGORIES = [
  {
    label: "All ages",
    value: "all",
  },
  {
    label: "Adults (age 12 - 99)",
    value: "adults",
  },
  {
    label: "Children (age 4 - 11)",
    value: "children",
  },
  {
    label: "Infants (age 0 - 3)",
    value: "infants",
  },
];
export const ATTRACTION_CATEGORIES = [
  {
    name: "Museum",
    value: "museum",
    subcategories: [],
  },
  {
    name: "Park",
    value: "park",
    subcategories: [],
  },
  {
    name: "Historical Site",
    value: "historical_site",
    subcategories: [],
  },
  {
    name: "Amusement Park",
    value: "amusement_park",
    subcategories: [],
  },
  {
    name: "Arts and Culture",
    value: "arts-culture",
    subcategories: [],
  },
  {
    name: "Food and drink",
    value: "food-drink",
    subcategories: [],
  },
  {
    name: "Fashion",
    value: "fashion",
    subcategories: [],
  },
  {
    name: "Travel & Outdoors",
    value: "travel",
    subcategories: [],
  },
  {
    name: "Science & Nature",
    value: "science-nature",
    subcategories: [],
  },
  {
    name: "Holiday & Seasonal",
    value: "holiday-seasonal",
    subcategories: [],
  },
  {
    name: "Tour",
    value: "tour",
    subcategories: [],
  },
  {
    name: "Music & Entertainment",
    value: "music-entertainment",
    subcategories: [],
  },
];
export const AUTOMOBILE_MODELS = [
  "Audi",
  "BMW",
  "Ford",
  "Jaguar",
  "Land Rover",
  "Mercedes-Benz",
  "Nissan",
  "Toyota",
  "Vauxhall",
  "Volkswagen",
  "Volvo",
  "Abarth",
  "Aixam",
  "Alfa Romeo",
  "Alpine",
  "Aston Martin",
  "Austin",
  "Bentley",
  "BMW",
  "BYD",
  "Cadillac",
  "Caterham",
  "Chevrolet",
  "Chrysler",
  "Citroen",
  "Coleman Milne",
  "Corvette",
  "Cupra",
  "Dacia",
  "Daewoo",
  "Daihatsu",
  "Dodge",
  "DS",
  "Eagle",
  "Ferrari",
  "Fiat",
  "Fisker",
  "Genesis",
  "Gwm Ora",
  "Honda",
  "Hummer",
  "Hyundai",
  "Ineos",
  "Infiniti",
  "Jaguar",
  "Jeep",
  "KGM",
  "KIA",
  "Lamborghini",
  "Land Rover",
  "Levc",
  "Lexus",
  "Lotus",
  "Marlin",
  "Maserati",
  "Mazda",
];
export const BOAT_MODELS = [
  {
    category: "Sailboats:",
    types: ["Catamaran", "Schooner", "Ketch", "Cutter"],
  },
  {
    category: "Motorboats:",
    types: ["Speedboat", "Cabin Cruiser", "Bowrider", "Cuddy Cabin", "Yacht"],
  },
  {
    category: "Fishing Boats:",
    types: [
      "Bass Boat",
      "Center Console",
      "Walkaround",
      "Sportfishing Yacht",
      "Pontoon Boat",
    ],
  },
  {
    category: "Personal Watercraft:",
    types: ["Jet Ski", "WaveRunner", "Sea-Doo"],
  },
  {
    category: "Luxury Boats:",
    types: ["Superyacht", "Mega Yacht", "Luxury Catamaran", "Motor Yacht"],
  },
  {
    category: "Houseboats:",
    types: [
      "Floating Home",
      "Luxury Houseboat",
      "River Houseboat",
      "Canal Boat",
    ],
  },
];
export const AUTOMOBILE_FUEL_TYPE = [
  "Hybrid",
  "Full Electric",
  "Diesel",
  "Gasoline",
];
export const BOAT_FUEL_TYPE = ["Automatic", "Diesel", "Gasoline"];
export const BOAT_IDEAL = [
  "Private parties",
  "Fishing trips",
  "Water skiing",
  "Weekend gateaways",
];
export const AUTOMOBILE_TRANSMISSIONS = ["Manual", "Automatic"];
export const BOAT_ENGINE_TYPE = [
  "Inboard",
  "Outboard",
  "Sail",
  "Hybrid/Electric",
];
export const ATTRACTION_FEATURES = [
  "Guided Tours",
  "Workshops",
  "Live Shows",
  "Exhibits",
];
export const ATTRACTION_INCLUDED = [
  "Wheelchair Accessible",
  "Kid-Friendly",
  "Pet-Friendly",
  "Food and Beverage",
];
export const SAFETY_MEASURES = ["On-Site Parking", "Nearby Parking", "None"];
export const TIMEZONES = [
  {
    offset: "GMT+01:00",
    name: "West Central Africa",
  },
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
  },
  {
    offset: "GMT",
    name: "Europe/Belfast",
  },
  {
    offset: "GMT",
    name: "Africa/Abidjan",
  },
  {
    offset: "GMT",
    name: "Europe/Dublin",
  },
  {
    offset: "GMT",
    name: "Europe/Lisbon",
  },
  {
    offset: "GMT",
    name: "Europe/London",
  },
  {
    offset: "UTC",
    name: "UTC",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
  },
];
export const RECURRING_FREQUENCIES = [
  "Every day",
  "Every week",
  "Every two weeks",
  "Every month",
  "Every two months",
  "Every quarter",
  "Every 6 months",
  "Every year",
  "Every 2 years",
];
export const BUSINESSES = [
  {
    name: "Hotel",
    icon: "tabler:bed",
    link: PATH_DASHBOARD.addListings,
  },
  {
    name: "Event",
    icon: "material-symbols:event-outline",
    link: PATH_DASHBOARD.addListings,
  },
  {
    name: "Automobile",
    icon: "mdi:car-outline",
    link: PATH_DASHBOARD.addListings,
  },
  {
    name: "Short-let",
    icon: "material-symbols:villa-outline",
    link: PATH_DASHBOARD.addListings,
  },
  {
    name: "Cruise",
    icon: "icon-park-outline:cruise",
    link: PATH_DASHBOARD.addListings,
  },
  {
    name: "Buy/Sell",
    icon: "material-symbols:storefront-outline",
    link: PATH_DASHBOARD.addListings,
  },
  {
    name: "Attractions",
    icon: "material-symbols:event-outline",
    link: PATH_DASHBOARD.addListings,
  },
];
export const AMENITY_TYPES = [
  {
    label: "Workspace",
    value: "workspace",
    icon: "material-symbols:bed-outline",
  },
  {
    label: "Parking Area",
    value: "parking",
    icon: "material-symbols-light:apartment",
  },
  { label: "Breakfast", value: "breakfast", icon: "map:boat-tour" },
  { label: "Wifi", value: "wifi", icon: "ph:building" },
  {
    label: "Swimming pool",
    value: "swimming_pool",
    icon: "material-symbols:villa-outline",
  },
  {
    label: "Electricity",
    value: "electricity",
    icon: "fluent-mdl2:breakfast",
  },
  {
    label: "Housekeeping",
    value: "housekeeping",
    icon: "hugeicons:guest-house",
  },
  {
    label: "24hrs Reception",
    value: "reception",
    icon: "material-symbols:cabin",
  },
  {
    label: "TV",
    value: "tv",
    icon: "material-symbols:bungalow-outline",
  },
  { label: "GYM", value: "gym", icon: "mingcute:house-2-line" },
  { label: "Pet Services", value: "pet", icon: "mdi:farm-home-outline" },
  { label: "Bar", value: "bar", icon: "material-symbols:chalet-outline" },
];
export const VEHICLE_AMENITY_TYPES = [
  {
    label: "Air conditioning",
    value: "air-conditioning",
    icon: "mdi:air-conditioner",
  },
  {
    label: "GPS navigation",
    value: "gps",
    icon: "tdesign:gps",
  },
  {
    label: "BT/Audio System",
    value: "audio_systm",
    icon: "mdi:bluetooth-audio",
  },
  { label: "Child seat", value: "child_seat", icon: "mdi:car-child-seat" },
  {
    label: "Backup camera",
    value: "backup_camera",
    icon: "ph:webcam-duotone",
  },
  {
    label: "Sunroof",
    value: "sunroof",
    icon: "material-symbols-light:solar-power-outline",
  },
];
export const BOAT_AMENITY_TYPES = [
  {
    label: "Bedrooms",
    value: "bedrooms",
    icon: "mdi:bedroom-outline",
  },
  {
    label: "Sun Deck",
    value: "sun-deck",
    icon: "material-symbols-light:sunny-snowing",
  },
  {
    label: "Lounge Area",
    value: "lounge",
    icon: "carbon:drink-01",
  },
  {
    label: "Kitchen/Galley",
    value: "kitchen",
    icon: "material-symbols:soup-kitchen-outline",
  },
  {
    label: "Bathroom",
    value: "bathroom",
    icon: "cil:bathroom",
  },
  {
    label: "Entertainment ",
    value: "entertainment ",
    icon: "ri:music-fill",
  },
];
export const BOAT_SAFETY_FEATURES = [
  {
    label: "Life Jackets",
    value: "life-jacket",
    icon: "game-icons:life-jacket",
  },
  {
    label: "Fire Extinguishers",
    value: "fire-extinguishers",
    icon: "ph:fire-extinguisher",
  },
  {
    label: "First Aid Kit",
    value: "firstaid-kit",
    icon: "icon-park-twotone:first-aid-kit",
  },
  {
    label: "GPS/Navigation",
    value: "gps",
    icon: "jam:gps",
  },
  {
    label: "VHF Radio",
    value: "vhf-radio",
    icon: "carbon:radio-push-to-talk",
  },
  {
    label: "Anchor",
    value: "anchor",
    icon: "material-symbols-light:anchor",
  },
];

export const LISTING_AMENITY_TYPES = [
  {
    label: "Swimming pool",
    value: "swimming_pool",
    icon: "material-symbols:villa-outline",
  },
  {
    label: "Electricity",
    value: "electricity",
    icon: "fluent-mdl2:breakfast",
  },
  { label: "GYM", value: "gym", icon: "gg:gym" },
  {
    label: "Garden",
    value: "garden",
    icon: "guidance:garden",
  },
  {
    label: "Fireplace",
    value: "fireplace",
    icon: "game-icons:fireplace",
  },
  {
    label: "Balcony",
    value: "balcony",
    icon: "material-symbols-light:balcony",
  },
];
export const LAND_AMENITY_TYPES = [
  {
    label: "Water",
    value: "water",
    icon: "game-icons:tap",
  },
  {
    label: "Street light",
    value: "street_light",
    icon: "game-icons:double-street-lights",
  },
  { label: "Gas", value: "gas", icon: "mdi:gas" },
  {
    label: "Drainage system",
    value: "drainage",
    icon: "ph:pipe-duotone",
  },
  {
    label: "24/7 light",
    value: "light",
    icon: "mage:electricity",
  },
  {
    label: "Internet line",
    value: "internet",
    icon: "hugeicons:internet-antenna-04",
  },
  {
    label: "Playground",
    value: "playground",
    icon: "mdi:playground-seesaw",
  },
  {
    label: "Security",
    value: "security",
    icon: "mdi:security-lock",
  },
];
export const OTHER_AMENITIES = [
  "Shower",
  "Spa and Wellness Center",
  "Dining table",
  "Fitness center / GYM",
  "Dining area",
  "Coffee shop",
  "Toaster",
  "Bar and Lounge",
  "Cable channels",
  "on-site restaurant",
  "Kitchenette",
  "Business center",
  "Microwave",
  "Lobby and Reception",
  "Refridgerator",
  "Concierge Desk",
  "TV",
  "Towels",
  "Toilet",
  "Kid's Play Area",
  "Rooftop Terrace",
  "Sauna and Steam Room",
];
export const OTHER_BOAT_AMENITIES = [
  "Jacuzzi",
  "Swimming Platform",
  "Water Sports Gear",
  "Fishing Equipment",
  "BBQ Grill",
];
export const OTHER_AUTOMOBILE_INCLUDED = ["Clean cars"];
export const ZONING_TYPES = [
  "Residential",
  "Commercial",
  "Agricultural",
  "Industrial",
  "Mixed-Use",
];
export const LAND_FACILITIES = ["Paved", "Gravel", "Dirt", "Others"];
export const LAND_NEARBY_FACILITIES = [
  "Schools",
  "Hospitals",
  "Shopping store",
  "Public Transportation",
];
export const PAYSTACK_KEY = "pk_live_d702d9481331a20dd35beded7e602138e7437479";
export const MONNIFY_KEY = "MK_TEST_MCMWD3L1DT";
export const CheckOnboardingDone = () => {
  const { user } = useAuth();
  const account = (user && user.account) || {};
  return (account && account.code) || "";
};
export const CopyToBoard = (link, enqueueSnackbar) => {
  navigator.clipboard.writeText(link);
  enqueueSnackbar("Link copied to clipboard.", {
    variant: "success",
  });
};
export const isValidEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const convertMsToDays = (ms) => {
  const msInOneSecond = 1000;
  const secondsInOneMinute = 60;
  const minutesInOneHour = 60;
  const hoursInOneDay = 24;

  const minutesInOneDay = hoursInOneDay * minutesInOneHour;
  const secondsInOneDay = secondsInOneMinute * minutesInOneDay;
  const msInOneDay = msInOneSecond * secondsInOneDay;

  return Math.ceil(ms / msInOneDay);
};
export const genLink = (type, uid, title) => {
  return `https://useafritas.com/${type}/${uid}-${title
    .replaceAll(" ", "-")
    .toLowerCase()}`;
};
export const getDaysBetweenDates = (dateOne, dateTwo) => {
  let differenceInMs = dateTwo.getTime() - dateOne.getTime();

  if (differenceInMs < 0) {
    differenceInMs = dateOne.getTime() - dateTwo.getTime();
  }

  return convertMsToDays(differenceInMs);
};
export const instanceId = "403498d8-d607-4791-b268-ac10b37928c9";
export const firebaseConfig = {
  apiKey: "AIzaSyDrQpp4Q_TqvZ7aS-oXW6cRI6IC7VV4o-M",
  authDomain: "afritas-9413a.firebaseapp.com",
  projectId: "afritas-9413a",
  storageBucket: "afritas-9413a.appspot.com",
  messagingSenderId: "553627276323",
  appId: "1:553627276323:web:7c29b697525ea46a8b8d96",
  measurementId: "G-54B01TWEF7",
};
export const GOOGLE = {
  clientId:
    "867966493030-0miu179o4mv5s35u141qksn3aif7s3il.apps.googleusercontent.com",
  clientSecret: "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",
};
export const mapKey = "AIzaSyCIoJOru4dY3Vx7vScp7X2M92eOYOuvOhw";
export const PAGE_DATA = [
  {
    path: PATH_DASHBOARD.root,
    permission: "view_dashboard_analytics",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.general.insights,
    permission: "view_insights",
    code: "0",
  },
  { path: PATH_DASHBOARD.general.account, permission: "", code: "0" },
  { path: PATH_DASHBOARD.general.settings, permission: "", code: "0" },
  {
    path: PATH_DASHBOARD.general.notification,
    permission: "view_notifications",
    code: "1",
  },
  {
    path: PATH_DASHBOARD.general.payment,
    permission: "view_payment_settings",
    code: "1",
  },
  {
    path: PATH_DASHBOARD.general.privacy,
    permission: "view_privacy_settings",
    code: "2",
  },
  {
    path: PATH_DASHBOARD.general.security,
    permission: "view_security_settings",
    code: "3",
  },
  {
    path: PATH_DASHBOARD.general.subscriptions,
    permission: "view_subscriptions",
    code: "3",
  },
  {
    path: PATH_DASHBOARD.bookings,
    permission: "view_bookings",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.addBookings,
    permission: "add_bookings",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.listings,
    permission: "view_listings",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.addListings,
    permission: "add_listings",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.earnings,
    permission: "view_earnings",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.staff,
    permission: "view_staff",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.roles,
    permission: "view_staff_roles",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.messages,
    permission: "view_messages",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.wallet,
    permission: "view_wallet",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.reviews,
    permission: "view_ratings_and_reviews",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.insights,
    permission: "view_insights",
    code: "18",
  },
];
export const CheckPerm = (perm) => {
  const { user } = useAuth();
  const permissions =
    user && user.role && user.role.permissions && user.role.permissions !== ""
      ? JSON.parse(user.role.permissions)
      : [];
  if (perm.split(",").length > 0) {
    return perm.split(",").some((p) => permissions.includes(p));
  }
  return perm === "" || permissions.includes(perm);
};
export const CheckSub = (action) => {
  /**
   * Sub response type
   * 0 - new user
   * 1 - active
   * 2 - cancelled.
   */
  return 1;

  const { user } = useAuth();
  const { subscription } = user;

  if (action === "" || action === "0") {
    // free features
    return 1;
  }

  if (subscription && Object.keys(subscription).includes("plan")) {
    const status = parseInt(subscription.status, 10);
    if (status === 1 || status === 2) {
      // active or cancelled subscription
      const features = subscription.features
        ? JSON.parse(subscription.features)
        : [];
      const isComplied =
        user &&
        user.compliance &&
        user.compliance.status &&
        parseInt(user.compliance.status, 10) === 1;
      if (action.split(",").length > 0) {
        if (
          (action.split(",").includes("8") ||
            action.split(",").includes("13")) &&
          !isComplied
        ) {
          // check if action is sales or online sales
          return 2;
        }
        return action.split(",").some((a) => features.includes(a)) ? 1 : 2;
      }
      if (features.includes(action)) {
        if ((action === "8" || action === "13") && !isComplied) {
          // check if action is sales or online sales
          return 2;
        }
        return 1;
      }
    }
    return 2;
  }
  // new user
  return 0;
};
