import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Tooltip,
  CardActionArea,
  Typography,
  Divider,
} from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import { CheckPerm, CheckSub, PAGE_DATA } from "../../utils/apis";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection, { NavItem } from "../../components/NavSection";
//
import { MHidden } from "../../components/@material-extend";
import {
  sidebarConfig,
  eventSidebarConfig,
  attractionSidebarConfig,
  propertySidebarConfig,
} from "./SidebarConfig";
import useAuth from "../../hooks/useAuth";

const DRAWER_WIDTH = 230;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool,
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Collapse Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const userType = (user && user.user && parseInt(user.user.type, 10)) || 0;

  const sidebarData = (
    (userType === 1 && eventSidebarConfig) ||
    (userType === 6 && attractionSidebarConfig) ||
    (userType === 5 && propertySidebarConfig) ||
    sidebarConfig
  ).map((obj) => {
    const items = obj.items.filter((it) => {
      const { code, permission } = PAGE_DATA.filter(
        (pp) => pp.path === it.path
      )[0];
      return (
        (permission === "" || CheckPerm(permission)) && CheckSub(code) === 1
      );
    });
    return {
      subheader: items.length > 0 ? obj.subheader : "",
      items,
      isNew: obj.isNew || false,
    };
  });

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <Logo disabledLink sx={{ height: 60 }} />
          </Box>
        </Stack>
      </Stack>
      <NavSection navConfig={sidebarData} isShow={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && (
        <Stack
          spacing={1}
          alignItems="start"
          sx={{
            px: 3,
            pb: 5,
            mt: 10,
            width: 1,
            textAlign: "start",
            display: { md: "flex", xs: "none" },
          }}
          divider={
            <Divider flexItem sx={{ borderColor: "rgba(64, 60, 60, 0.2)" }} />
          }
        >
          <NavItem
            item={{
              title: "Logout",
              path: "logout",
              icon: <LogoutOutlinedIcon />,
            }}
            active={() => false}
            isShow
          />
          <Typography
            variant="body2"
            sx={{
              textAlign: "start",
              fontSize: 13,
              fontWeight: 400,
              color: "rgba(64, 60, 60, 0.7)",
            }}
          >
            Copyright &copy; {new Date().getFullYear()} Afritas.
            <br />
            All rights reserved
          </Typography>
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        displayPrint: "none",
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
              border: "none",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
