import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";
import { formatDate } from "../../utils/formatTime";

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  isDeleteLoading: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  summary: {},
  dashboard: {},
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeleteError(state) {
      state.isDeleteLoading = true;
      state.deleteError = false;
    },
    hasDeleteError(state, action) {
      state.isDeleteLoading = false;
      state.deleteError = action.payload;
    },
    markSuccess(state, action) {
      const marked = action.payload.split(",");
      state.notifications = state.notifications.map((obj) => {
        obj.status = marked.includes(obj.nid) ? 1 : obj.status;
        return obj;
      });
      state.isLoading = false;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    getDashboardSuccess(state, action) {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.deleteError = "";
      state.dashboard = action.payload;
    },
    deletedSuccess(state, action) {
      state.deleteError = "success";
      state.isDeleteLoading = false;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getDashboard(date, selectedFilter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.dashboard, {
        get: formatDate(date),
        selectedFilter,
      });
      const { status, data } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getDashboardSuccess(data));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch dashboard at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function cancelBooking(remove) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.bookings, { remove });
      if (response.data.status === "success") {
        dispatch(slice.actions.deletedSuccess(remove));
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occured! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
