import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { frontendPages } from "./paths";

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = !frontendPages.includes(pathname);
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        { path: "dashboard", element: <Dashboard /> },
        {
          path: "settings",
          children: [
            { path: "", element: <Settings /> },
            { path: ":tab", element: <Settings /> },
          ],
        },
        { path: "log", element: <Log /> },
        {
          path: "bookings",
          children: [
            { path: "", element: <Bookings /> },
            { path: "add", element: <AddBooking /> },
            { path: ":bookingId", element: <AddBooking /> },
          ],
        },
        {
          path: "tickets",
          children: [
            { path: "", element: <Bookings /> },
            { path: "types", element: <TicketTypes /> },
            { path: "checkedin", element: <CheckedIn /> },
            { path: ":bookingId", element: <AddBooking /> },
            { path: "add", element: <AddBooking /> },
          ],
        },
        {
          path: "earnings",
          children: [
            { path: "", element: <Earnings /> },
            { path: "add", element: <AddBooking /> },
          ],
        },
        {
          path: "listings",
          children: [
            { path: "", element: <ListingChooser /> },
            { path: "add", element: <AddChooser /> },
            { path: "edit/:listingId", element: <AddChooser /> },
          ],
        },
        {
          path: "staff",
          children: [
            { path: "", element: <Staffs /> },
            { path: "roles", element: <StaffRoles /> },
            { path: "edit/:listingId", element: <AddChooser /> },
          ],
        },
        {
          path: "wallet",
          children: [
            { path: "", element: <Wallet /> },
            { path: "add", element: <AddChooser /> },
            { path: "edit/:listingId", element: <AddChooser /> },
          ],
        },
        { path: "select-business-type", element: <SelectBusinessType /> },
        { path: "reviews", element: <Reviews /> },
        { path: "messages", element: <Messages /> },
        { path: "insights", element: <SelectInsights /> },
        { path: "leads", element: <Leads /> },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        {
          path: "verify",
          element: (
            <AuthGuard>
              <VerifyCode />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// admin pages
const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));
const Wallet = Loadable(lazy(() => import("../pages/dashboard/Wallet")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const SelectBusinessType = Loadable(
  lazy(() => import("../pages/dashboard/SelectBusinessType"))
);
const Messages = Loadable(lazy(() => import("../pages/dashboard/Messages")));
const SelectInsights = Loadable(
  lazy(() => import("../pages/dashboard/SelectInsights"))
);
const Reviews = Loadable(lazy(() => import("../pages/dashboard/Reviews")));
const Bookings = Loadable(lazy(() => import("../pages/dashboard/Bookings")));
const Earnings = Loadable(lazy(() => import("../pages/dashboard/Earnings")));
const Leads = Loadable(lazy(() => import("../pages/dashboard/Leads")));
const TicketTypes = Loadable(
  lazy(() => import("../pages/dashboard/TicketTypes"))
);
const CheckedIn = Loadable(lazy(() => import("../pages/dashboard/CheckedIn")));
const ListingChooser = Loadable(
  lazy(() => import("../pages/dashboard/ListingChooser"))
);
const Staffs = Loadable(lazy(() => import("../pages/dashboard/Staffs")));
const StaffRoles = Loadable(
  lazy(() => import("../pages/dashboard/StaffRoles"))
);
const Log = Loadable(lazy(() => import("../pages/dashboard/Log")));
const AddBooking = Loadable(
  lazy(() => import("../pages/dashboard/AddBooking"))
);
const AddChooser = Loadable(
  lazy(() => import("../pages/dashboard/AddChooser"))
);
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));

// auth
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/Verify"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
