import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useLocation, useParams } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Popover,
  Link,
} from "@mui/material";
// hooks
import { MHidden } from "../../components/@material-extend";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import AccountPopover from "./AccountPopover";
import { BUSINESS_SWITCHES, PAGE_TITLES } from "../../utils/apis";
import { Icon } from "@iconify/react";
import useAuth from "../../hooks/useAuth";
import ToggleAccount from "./ToggleAccount";
import Logo from "../../components/Logo";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 230;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  if (Object.prototype.hasOwnProperty.call(params, "*")) {
    delete params["*"];
  }
  return location.pathname.split("/").length - 1 === 1
    ? location.pathname
    : Object.values(params).reduce(
        (path, param) => path.replace(`/${param}`, ""),
        location.pathname
      );
};
export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const pathname = useBasePath();
  const [title, setTitle] = useState("");
  const [openNotification, setOpenNotification] = useState(null);
  const { user } = useAuth();
  const businessType = parseInt((user && user.user && user.user.type) || 0, 10);

  useEffect(() => {
    try {
      setTitle(PAGE_TITLES[pathname] || "Dashboard");
    } catch (err) {
      console.log(err);
    }
  }, [pathname]);

  return (
    <RootStyle
      sx={{
        displayPrint: "none",
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon="material-symbols:menu" />
          </IconButton>
        </MHidden>
        <MHidden width="smUp">
          <Box sx={{ "& a": { width: 50 } }}>
            <Logo />
          </Box>
        </MHidden>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "1.5rem",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            display: { md: "block", xs: "none" },
          }}
        >
          {title}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 0.5, sm: 3 }}
          divider={
            <Box
              sx={{
                height: 1,
                display: { md: "flex", xs: "none" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider
                flexItem
                orientation="vertical"
                variant="middle"
                sx={{
                  borderColor: "rgba(0, 0, 0, 0.2)",
                  height: 30,
                  m: "0 auto",
                }}
              />
            </Box>
          }
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
              sx={{
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.08)",
                bgcolor: "common.white",
              }}
              onClick={(e) => setOpenNotification(e.target)}
            >
              <NotificationsActiveOutlinedIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 1)" }}
              />
            </IconButton>
            <IconButton
              sx={{
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.08)",
                bgcolor: "common.white",
              }}
            >
              <EmailOutlinedIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 1)" }}
              />
            </IconButton>
            <MHidden width="smDown">
              {BUSINESS_SWITCHES.includes(businessType) && <ToggleAccount />}
            </MHidden>
          </Stack>

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
      {openNotification && (
        <Popover
          id="openNotification"
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          onClose={() => setOpenNotification(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack sx={{ px: 2, py: 3, width: 300 }} spacing={3}>
            <Typography
              variant="body1"
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: "rgba(64, 60, 60, 1)",
              }}
            >
              Notification
            </Typography>
            <Stack spacing={2} divider={<Divider flexItem />}>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="start"
                spacing={1}
              >
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                  }}
                >
                  <Icon
                    icon="material-symbols:mail-outline"
                    style={{
                      color: "rgba(98, 98, 98, 1)",
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
                <Link
                  sx={{
                    fontSize: 13,
                    fontWeight: 300,
                    color: "rgba(64, 60, 60, 1)",
                  }}
                >
                  Please confirm your email address by clicking on the link
                  we've just emailed you. If you cannot find the email, you can
                  request a new confirmation email or change your email address.
                </Link>
              </Stack>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="start"
                spacing={1}
              >
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                  }}
                >
                  <Icon
                    icon="material-symbols:mail-outline"
                    style={{
                      color: "rgba(98, 98, 98, 1)",
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
                <Link
                  sx={{
                    fontSize: 13,
                    fontWeight: 300,
                    color: "rgba(64, 60, 60, 1)",
                  }}
                >
                  Please confirm your email address by clicking on the link
                  we've just emailed you. If you cannot find the email, you can
                  request a new confirmation email or change your email address.
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Popover>
      )}
    </RootStyle>
  );
}
