import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// material
import { Container, Stack, Grid, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {
  Account,
  General,
  ID,
  Security,
  PaymentMethods,
  Notifications,
  Subscriptions,
  Policy,
} from "../../components/_dashboard/settings";
import { Icon } from "@iconify/react";
import { isMobile } from "react-device-detect";

// ----------------------------------------------------------------------
const ACCOUNT_TABS = [
  {
    value: "profile",
    icon: "material-symbols:subject",
    title: "Personal info",
    subtitle: "Provide personal information on how we can reach you.",
    content: <General />,
  },
  {
    value: "security",
    icon: "material-symbols:password",
    title: "Login and security",
    subtitle: "Update your password to secure your account.",
    content: <Security />,
  },
  {
    value: "payment",
    icon: "material-symbols:payments",
    title: "Payment and payouts",
    subtitle: "Take a detailed look at your payment and payout transactions",
    content: <Account />,
  },
  {
    value: "notification",
    icon: "mingcute:notification-line",
    title: "Notification",
    subtitle: "Stay Informed with Notifications\n\n",
    content: <Notifications />,
  },
  /*{
    value: "insight",
    icon: "material-symbols:search-insights-rounded",
    title: "Insights",
    subtitle: "Gain Valuable Insights for your listing performances",
  },*/
  {
    value: "privacy",
    icon: "ic:round-lock-open",
    title: "Privacy and sharing",
    subtitle: "Manage your data, connected services, and sharing settings",
    content: <Policy />,
  },
  {
    value: "subscriptions",
    icon: "material-symbols:subscriptions-outline",
    title: "Subscriptions",
    subtitle: "Manage Your Subscription",
    content: <Subscriptions />,
  },
  {
    value: "id",
    title: "Government ID",
    subtitle: "Manage Your Subscription",
    isHidden: true,
    content: <ID />,
  },
  {
    value: "payment_methods",
    title: "Payment Methods",
    subtitle: "Manage Your Subscription",
    isHidden: true,
    content: <PaymentMethods />,
  },
];
export default function Settings() {
  const params = useParams();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const [page, setPage] = useState({});
  useEffect(() => {
    const value = params.tab || "";
    if (value) {
      const selected = ACCOUNT_TABS.filter((tab) => tab.value === value);
      if (selected.length > 0) {
        setPage(selected[0]);
      }
    } else {
      setPage({});
    }
  }, [params]);

  return (
    <Page
      title="Account Settings"
      sx={{ bgcolor: { md: "common.white", xs: "transparent" }, py: 4 }}
    >
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading={(page && page.title) || "Account Settings"}
          isBack={isMobile ? () => navigate(-1) : false}
          links={
            (!isMobile &&
              page &&
              page.title &&
              (page.value === "id"
                ? [
                    { name: "Account", href: PATH_DASHBOARD.general.settings },
                    {
                      name: "Personal Info",
                      href: PATH_DASHBOARD.general.account,
                    },
                    { name: page.title },
                  ]
                : [
                    { name: "Account", href: PATH_DASHBOARD.general.settings },
                    { name: page.title },
                  ])) ||
            null
          }
        />
        {(page && page.content) || (
          <Stack spacing={5} sx={{ width: 1 }}>
            <Grid container spacing={{ md: 3, xs: 0 }}>
              {ACCOUNT_TABS.filter((item) => !item.isHidden).map(
                (tab, index) => (
                  <Grid
                    item
                    key={index}
                    md={4}
                    xs={12}
                    sx={{ pb: { md: 0, xs: 2 } }}
                  >
                    <Stack
                      sx={{
                        bgcolor: "common.white",
                        boxShadow: {
                          md: "1px -1px 11.6px 0px rgba(0, 0, 0, 0.15)",
                          xs: "none",
                        },
                        borderRadius: { md: 3, xs: 2 },
                        p: 2,
                        px: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/settings/${tab.value}`)}
                      spacing={2}
                    >
                      <Icon
                        icon={tab.icon}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "1rem", fontWeight: 500, pt: 2 }}
                      >
                        {tab.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "rgba(113, 113, 113, 1)",
                          fontSize: 14,
                          fontWeight: 400,
                          whiteSpace: "pre-line",
                        }}
                      >
                        {tab.subtitle}
                      </Typography>
                    </Stack>
                  </Grid>
                )
              )}
            </Grid>
          </Stack>
        )}
      </Container>
    </Page>
  );
}
